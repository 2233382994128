import { Loader } from 'app/components/loader';
import { FC, lazy, Suspense } from 'react';

const ManageWatchlist = lazy(() => import('app/components/manage-watchlist/manage-watchlist.container'));

export const ManageWatchlistPage: FC = () => (
  <Suspense fallback={<Loader testId="manage-watchlist-loader" />}>
    <ManageWatchlist />
  </Suspense>
);
