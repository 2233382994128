import { Loader } from 'app/components/loader';
import { VisitorGroupsCreationForm } from 'app/components/visitor-groups/visitor-groups-creation/visitor-groups-creation-form';
import { FC, lazy, Suspense } from 'react';

const VisitorGroupsModal = lazy(() => import('app/components/visitor-groups/visitor-groups-modal/visitor-groups-modal.container'));

export const VisitorGroupCreatePage: FC = () => (
  <Suspense fallback={<Loader testId="create-visitor-group-form-loader" />}>
    <VisitorGroupsModal FormComponent={VisitorGroupsCreationForm} />
  </Suspense>
);
