import { FC } from 'react';
import { Formik } from 'formik';
import { FormValues, VisitorGroupsFormProps } from '../visitor-groups.interfaces';

import { useInitialValues } from './hooks/use-initial-values.hook';
import { useValidationSchema } from '../visitor-groups-modal/hooks/use-validation-schema.hook';
import { useFormSubmitHandler } from './hooks/use-form-submit-handler.hook';

import { VisitorGroupsModalView } from '../visitor-groups-modal/visitor-groups-modal.view';

export const VisitorGroupsCreationForm: FC<VisitorGroupsFormProps> = ({ onClose }) => {
  const initialValues = useInitialValues();
  const validationSchema = useValidationSchema();
  const { handleSubmit } = useFormSubmitHandler();

  return (
    <Formik<FormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <VisitorGroupsModalView onClose={onClose} />
    </Formik>
  );
};
