import { FC } from 'react';
import { IconWrapper } from './reset-visitor.styles';

interface ResetVisitorProps {
  onResetVisitor: VoidFunction;
}

export const ResetVisitor: FC<ResetVisitorProps> = ({ onResetVisitor }) => (
  <IconWrapper onClick={onResetVisitor} data-testid="reset-visitor">
    <span className="material-icons">restart_alt</span>
  </IconWrapper>
);
