import { visitorGroupsSelector } from 'app/store/visitor-groups/selectors';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import type { FormValues, UseVisitorGroupModalHeaderResponse } from '../../visitor-groups.interfaces';
import { useField, useFormikContext } from 'formik';
import { useUniqueGroupNameValidation } from '../../../visits/visit-form/visit-form-content/visitors/group-visit/use-unique-group-name-validation.hook';
import { hasEditGroupsPermissionsSelector } from 'app/store/user-permissions/selectors';

export const useVisitorGroupModalHeader = (name: string, shouldSubmitOnBlur: boolean): UseVisitorGroupModalHeaderResponse => {
  const [field, , helpers] = useField('groupName');
  const [inputValue, setInputValue] = useState(field.value);
  const [displayValue, setDisplayValue] = useState(field.value);
  const { dirty, errors, handleSubmit } = useFormikContext<FormValues>();
  const hasEditPermissions = useSelector(hasEditGroupsPermissionsSelector);

  useUniqueGroupNameValidation();

  const groups = useSelector(visitorGroupsSelector);

  const groupNames = useMemo(() => groups?.map((group) => group?.name?.toLowerCase()), [groups]);
  const existingName = useMemo(() => {
    if (name.length === 1) {
      return name;
    }
    return name.split(' ');
  }, [name]);
  const nameExists = useMemo(() => groupNames?.some((groupName: string | undefined) => groupName === inputValue.toLowerCase()), [groupNames, inputValue]);
  const valueNames = useMemo(() => inputValue.split(' '), [inputValue]);
  const namesForInitals = existingName?.[0] !== '' ? [existingName[0]?.[0], existingName[1]?.[0]] : [valueNames[0]?.[0], valueNames[1]?.[0]];
  const initials = useMemo(() => namesForInitals.filter(Boolean).join('').toUpperCase(), [namesForInitals]);

  const onBlur = useCallback((text: string) => {
    setDisplayValue(text);
    helpers.setValue(text);
    if (shouldSubmitOnBlur && !errors.groupName && dirty) {
      handleSubmit();
    }
  }, [dirty, errors.groupName, helpers.setValue, nameExists, setDisplayValue, shouldSubmitOnBlur]);

  const onSetInputValue = useCallback((text: string) => {
    setInputValue(text);
    helpers.setValue(text);
  }, [field, helpers.setValue, setInputValue]);

  return {
    hasEditPermissions,
    initials,
    inputValue,
    nameExists,
    onBlur,
    onSetInputValue,
    setValue: setDisplayValue,
    value: displayValue,
  };
};
