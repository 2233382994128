import { useMemo } from 'react';
import { HqOTableEnums } from '@hqo/react-components-library/dist/organisms/hqo-table';
import { TableColumnOptions } from '../use-table-columns.hook';
import { useTextColumn } from './use-text-column.hook';
import { SortOrder } from '../../../store/visits/types';

export type SortableColumnConfig<T extends object> = Omit<
  Partial<TableColumnOptions<T>>,
  'id' | 'headerType' | 'headerProps'
>;

export const useSortableColumn = <T extends object>(
  id: keyof T,
  sortOrder: SortOrder,
  onSortChange: (newOrder: SortOrder) => void,
  config: SortableColumnConfig<T>,
): TableColumnOptions<T> => {
  const textColumn = useTextColumn(id, config);

  return useMemo(
    () => ({
      ...textColumn,
      headerType: HqOTableEnums.HeaderTypesEnum.sort,
      headerProps: {
        sortDirection: sortOrder,
        onSort: onSortChange,
      },
    }),
    [textColumn, sortOrder, onSortChange],
  );
};
