import styled from 'styled-components';
import { Panel } from 'app/components/panel';

export const StyledPanel = styled(Panel)<{ showMoreOptions: boolean }>`
  display: flex;
  justify-content: space-around;
  width: auto;
  height: auto;
  gap: 24px;
  padding: ${({ showMoreOptions }) => (showMoreOptions ? '36px 24px 16px' : '16px 24px 0px')};
  margin-bottom: 16px;
  position: relative;
`;

export const ControlsGroup = styled.div`
  display: flex;
  justify-content: space-between;
  width: auto;
  height: auto;
  gap: 8px;
`;

export const AddButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;
