import { HqOTableColumn } from '@hqo/react-components-library/dist/organisms/hqo-table/types';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { MessageIds } from '../../../i18n';

export interface TableColumnOptions<TData, TColumns = never> extends Omit<HqOTableColumn, 'width' | 'label' | 'id'> {
  id: keyof TData | TColumns;
  weight: number;
}

export const useTableColumns = <TData, TColumns extends string = never>(
  tableTranslationsKeyPrefix: string,
  columns: TableColumnOptions<TData, TColumns>[],
): HqOTableColumn[] => {
  const { formatMessage } = useIntl();

  return useMemo(() => {
    const totalWeight = columns.reduce((acc, column) => acc + column.weight, 0);

    return columns.map(({ weight, id, ...column }) => ({
      ...column,
      id: id.toString(),
      label: formatMessage({ id: `${tableTranslationsKeyPrefix}.columns.${id.toString()}` as MessageIds }),
      width: `${Math.round((weight / totalWeight) * 100)}%`,
    }));
  }, [columns, formatMessage]);
};
