import { RootAction } from 'app/store/actions';
import { buildingUuidSelector, timezoneSelector } from 'app/store/building/selectors';
import { RootDependencies } from 'app/store/dependencies';
import { RootState } from 'app/store/reducer';
import {
  cancelVisit,
  cancelVisitForCurrentBuilding,
  checkInVisit,
  checkInVisitForCurrentBuilding,
  checkOutVisit,
  checkOutVisitForCurrentBuilding,
  createVisits,
  createVisitsForCurrentBuilding,
  fetchVisit,
  fetchVisits,
  notifyHostVisit,
  revertVisitStatus,
  searchVisitByCredential,
  updateVisit,
  updateVisitForCurrentBuilding,
  visitUpdatedEvent,
  visitUpdatedEventForCurrentView,
} from 'app/store/visits/actions';
import {
  CreateVisitsOperationParams,
  UpdateVisitOperationParams,
  VisitOperationParams,
  VisitStatus,
} from 'app/store/visits/types';
import { Epic } from 'redux-observable';
import { catchError, concat, EMPTY, filter, map, mergeMap, of, switchMap, withLatestFrom, tap } from 'rxjs';
import { isActionOf } from 'typesafe-actions';
import { toast } from 'react-toastify';
import { rootPathSelector } from 'app/store/config/selectors';
import { VISITS_PATH } from 'app/shared/consts';
import { clearHosts } from 'app/store/hosts/actions';
import min from 'date-fns/min';
import { formatInTimeZone } from 'date-fns-tz';
import {
  VISITS_DATE_FROM_FILTER_QUERY_PARAM,
  VISITS_DATE_TO_FILTER_QUERY_PARAM,
} from 'app/components/visits/visits-date-filter/use-visits-date-filter.hook';
import { isDefined } from '../../shared/utils/is-defined';
import { visitsSelector } from 'app/store/visits/selectors';
import { showVisitUpdatedNotification } from 'app/shared/utils/visits-notifications';
import { setPeopleForPendingWatchlistModal } from '../watchlist/actions';
import { VISITS_SEARCH_QUERY_PARAM } from 'app/components/visits/shared/visits-search.hook';
import * as qs from 'qs';

const DATE_FORMAT = 'yyyy-MM-dd';

export const fetchVisitsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchVisits.request)),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      apiClient(state)
        .fetchVisits(action.payload)
        .pipe(
          map((response) => fetchVisits.success({ params: action.payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.visits.fetch.error' }));

            return of(fetchVisits.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );

export const fetchVisitEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchVisit.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      apiClient(state)
        .fetchVisit(action.payload)
        .pipe(
          map((response) => fetchVisit.success({ params: action.payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.visit.fetch.error' }));

            return of(fetchVisit.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );

export const revertVisitStatusEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(revertVisitStatus.request)),
    withLatestFrom(state$),
    mergeMap(([{ payload: params }, state]) => apiClient(state)
      .revertVisitStatus(params.visitUuid)
      .pipe(
        map(({ response }) => revertVisitStatus.success({ params, response: response.data })),
        catchError((error: Error) => {
          toast.error(intl.formatMessage({ id: 'notifications.visits.reverStatus.error' }));

          return of(revertVisitStatus.failure({ params, response: error }));
        }),
      )),
  );

export const checkInVisitEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(checkInVisitForCurrentBuilding)),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const buildingUuid = buildingUuidSelector(state);
      const params: VisitOperationParams = { ...action.payload, buildingUuid };

      return concat(
        of(checkInVisit.request(params)),
        apiClient(state)
          .checkInVisit(params.visitUuid)
          .pipe(
            map(({ response }) => {
              toast.success(intl.formatMessage({ id: 'notifications.visits.checkIn.success' }));

              return checkInVisit.success({ params, response });
            }),
            catchError((error: Error) => {
              toast.error(intl.formatMessage({ id: 'notifications.visits.checkIn.error' }));

              return of(checkInVisit.failure({ params, response: error }));
            }),
          ),
      );
    }),
  );

export const checkOutVisitEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(checkOutVisitForCurrentBuilding)),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const buildingUuid = buildingUuidSelector(state);
      const params: VisitOperationParams = { ...action.payload, buildingUuid };

      return concat(
        of(checkOutVisit.request(params)),
        apiClient(state)
          .checkOutVisit(params.visitUuid)
          .pipe(
            map(({ response }) => {
              toast.success(intl.formatMessage({ id: 'notifications.visits.checkOut.success' }));

              return checkOutVisit.success({ params, response });
            }),
            catchError((error: Error) => {
              toast.error(intl.formatMessage({ id: 'notifications.visits.checkOut.error' }));

              return of(checkOutVisit.failure({ params, response: error }));
            }),
          ),
      );
    }),
  );

export const cancelVisitEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(cancelVisitForCurrentBuilding)),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const buildingUuid = buildingUuidSelector(state);
      const params: VisitOperationParams = { ...action.payload, buildingUuid };

      return concat(
        of(cancelVisit.request(params)),
        apiClient(state)
          .cancelVisit(params.visitUuid)
          .pipe(
            map(() => {
              toast.success(intl.formatMessage({ id: 'notifications.visits.cancel.success' }));

              return cancelVisit.success({ params });
            }),
            catchError((error: Error) => {
              toast.error(intl.formatMessage({ id: 'notifications.visits.cancel.error' }));

              return of(cancelVisit.failure({ params, response: error }));
            }),
          ),
      );
    }),
  );

export const notifyHostEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(notifyHostVisit.request)),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const buildingUuid = buildingUuidSelector(state);
      const params: VisitOperationParams = { buildingUuid, visitUuid: action.payload.visitUuid };

      return apiClient(state)
        .notifyHost(action.payload.visitUuid)
        .pipe(
          map(() => {
            toast.success(intl.formatMessage({ id: 'notifications.notifyHost.success' }));
            return notifyHostVisit.success({ params });
          }),
          catchError((response: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.notifyHost.error' }));
            return of(notifyHostVisit.failure({ params, response }));
          }),
        );
    }),
  );

export const createVisitsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(createVisitsForCurrentBuilding)),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const buildingUuid = buildingUuidSelector(state);
      const params: CreateVisitsOperationParams = { buildingUuid, visits: action.payload.visits };

      return concat(
        of(createVisits.request(params)),
        apiClient(state)
          .createVisits(action.payload)
          .pipe(
            mergeMap(({ response }) => {
              toast.success(intl.formatMessage({ id: 'notifications.visits.create.success' }));
              const { visits } = response;

              const pendingWatchlistUsers = visits
                ?.filter((visit) => visit.status === VisitStatus.PENDING_WATCHLIST)
                ?.map(({ uuid, visitor_name }) => ({ uuid, visitor_name })) ?? [];

              if (pendingWatchlistUsers.length) {
                return of(
                  clearHosts({ buildingUuid }),
                  createVisits.success({ params, response }),
                  setPeopleForPendingWatchlistModal({
                    buildingUuid,
                    pendingWatchlistModalPeople: pendingWatchlistUsers,
                  }),
                );
              }
              return of(clearHosts({ buildingUuid }), createVisits.success({ params, response }));
            }),
            catchError((error: Error) => {
              toast.error(intl.formatMessage({ id: 'notifications.visits.create.error' }));

              return of(createVisits.failure({ params, response: error }));
            }),
          ),
      );
    }),
  );

export const createVisitsSuccessEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { navigate },
) =>
  action$.pipe(
    filter(isActionOf(createVisits.success)),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const rootPath = rootPathSelector(state);
      const { visits } = action.payload.response;
      const visitStartDates = visits
        .map(({ arrival_time }) => (arrival_time ? new Date(arrival_time) : null))
        .filter(isDefined);

      if (!visitStartDates.length) {
        navigate(`${rootPath}/${VISITS_PATH}`);

        return EMPTY;
      }

      const earliestVisitStartDate = min(visitStartDates);
      const buildingTimeZone = timezoneSelector(state);
      const dateInBuildingTimezone = formatInTimeZone(earliestVisitStartDate, buildingTimeZone, DATE_FORMAT);

      navigate(
        `${rootPath}/${VISITS_PATH}?${VISITS_DATE_FROM_FILTER_QUERY_PARAM}=${dateInBuildingTimezone}&${VISITS_DATE_TO_FILTER_QUERY_PARAM}=${dateInBuildingTimezone}`,
      );

      return EMPTY;
    }),
  );

export const updateVisitEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(updateVisitForCurrentBuilding)),
    withLatestFrom(state$),
    mergeMap(([action, state]) => {
      const buildingUuid = buildingUuidSelector(state);
      const params: UpdateVisitOperationParams = { ...action.payload, buildingUuid };

      return concat(
        of(updateVisit.request(params)),
        apiClient(state)
          .updateVisit(params)
          .pipe(
            map(({ response }) => {
              toast.success(intl.formatMessage({ id: 'notifications.visit.update.success' }));

              return updateVisit.success({ params, response });
            }),
            catchError((error: Error) => {
              toast.error(intl.formatMessage({ id: 'notifications.visit.update.error' }));

              return of(updateVisit.failure({ params, response: error }));
            }),
          ),
      );
    }),
  );

export const updateVisitSuccessEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { navigate },
) =>
  action$.pipe(
    filter(isActionOf(updateVisit.success)),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const rootPath = rootPathSelector(state);

      navigate(`${rootPath}/${VISITS_PATH}`);

      return EMPTY;
    }),
  );

export const visitUpdatedEventEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { intl },
) =>
  action$.pipe(
    filter(isActionOf(visitUpdatedEvent)),
    withLatestFrom(state$),
    filter(([{ payload }, state]) => visitsSelector(state).some((visit) => visit.uuid === payload.visit.uuid)),
    tap(([{ payload }]) => {
      showVisitUpdatedNotification(payload, intl);
    }),
    map(([{ payload }, state]) =>
      visitUpdatedEventForCurrentView({ ...payload, buildingUuid: buildingUuidSelector(state) }),
    ),
  );

export const searchVisitByCredentialEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl, navigate },
) =>
  action$.pipe(
    filter(isActionOf(searchVisitByCredential.request)),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      apiClient(state)
        .searchVisitByCredential(action.payload)
        .pipe(
          map(({ response: { data } }) => {
            const rootPath = rootPathSelector(state);
            const buildingTimeZone = timezoneSelector(state);
            const query = qs.stringify(
              {
                [VISITS_DATE_FROM_FILTER_QUERY_PARAM]: formatInTimeZone(
                  data.arrival_time,
                  buildingTimeZone,
                  DATE_FORMAT,
                ),
                [VISITS_DATE_TO_FILTER_QUERY_PARAM]: formatInTimeZone(data.arrival_time, buildingTimeZone, DATE_FORMAT),
                [VISITS_SEARCH_QUERY_PARAM]: data.visitor_name,
              },
              { addQueryPrefix: true },
            );

            navigate(`${rootPath}/${VISITS_PATH}${query}`, { replace: true });

            return searchVisitByCredential.success({ params: action.payload, response: data });
          }),
          catchError((response: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.searchVisitByCredential.fetch.error' }));

            return of(searchVisitByCredential.failure({ params: action.payload, response }));
          }),
        ),
    ),
  );
