import { BulkAction, HqoTableBulkActionsProps } from '@hqo/react-components-library/dist/organisms/hqo-table/types';
import { MessageIds } from 'i18n';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export const useBulkProps = (
  bulkActions: BulkAction[] | undefined,
  tableTranslationsKeyPrefix: string,
): HqoTableBulkActionsProps | undefined => {
  const { formatMessage } = useIntl();

  return useMemo(() => {
    if (!bulkActions?.length) {
      return undefined;
    }

    return {
      bulkActions,
      labels: {
        deselectAllLabel: formatMessage({
          id: `${tableTranslationsKeyPrefix}.bulkActions.deselectAll` as MessageIds,
        }),
        selectAllButtonLabel: (count: number) =>
          formatMessage({ id: `${tableTranslationsKeyPrefix}.bulkActions.selectAllButton` as MessageIds }, { count }),
        allItemsSelected: (count: number) =>
          formatMessage({ id: `${tableTranslationsKeyPrefix}.bulkActions.allItemsSelected` as MessageIds }, { count }),
        partialSelection: (count: number) =>
          formatMessage({ id: `${tableTranslationsKeyPrefix}.bulkActions.partialSelection` as MessageIds }, { count }),
      },
    };
  }, [bulkActions, tableTranslationsKeyPrefix, formatMessage]);
};
