import { useCallback, useMemo } from 'react';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { HqOTableEnums } from '@hqo/react-components-library/dist/organisms/hqo-table';
import { BACK_ROUTE_QUERY_PARAM } from 'app/components/visits/visit-modal/visit-modal.consts';
import { TableColumnOptions } from 'app/components/base-table/use-table-columns.hook';
import { useQueryParam } from 'app/shared/hooks/use-query-param';
import { TextColumnConfig, useTextColumn } from './use-text-column.hook';

export type LinkColumnConfig<T extends object> = TextColumnConfig<T> & {
  includeBackRoute?: boolean;
};

export const useNavLinkColumn = <T extends object>(
  id: keyof T,
  path: string,
  config: LinkColumnConfig<T>,
): TableColumnOptions<T> => {
  const textColumn = useTextColumn(id, config);

  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const [backRouteParam] = useQueryParam(BACK_ROUTE_QUERY_PARAM);
  const backRoute = backRouteParam || encodeURIComponent(`${pathname}${search}`);

  const onClickHandler = useCallback(
    (rowData: Record<string, unknown>) => {
      const backRouteQuery = config.includeBackRoute ? `?${BACK_ROUTE_QUERY_PARAM}=${backRoute}` : '';
      navigate(`${generatePath(path, rowData)}${backRouteQuery}`);
    },
    [navigate, path, config.includeBackRoute, backRoute],
  );

  return useMemo(
    () => ({
      ...textColumn,
      columnType: HqOTableEnums.ColumnTypesEnum.link,
      columnProps: {
        onLinkClick: onClickHandler,
      },
    }),
    [textColumn, onClickHandler],
  );
};
