import { useBooleanState } from '@hqo/react-components-library/dist/hooks';
import { Wrapper, AvatarImage, Fallback } from './avatar.styles';
import { FC, useEffect } from 'react';
import { Skeleton } from '@hqo/react-components-library/dist/atoms/skeleton';

interface AvatarProps {
  avatarUrl?: string;
  fallback?: string;
  backgroundColor?: string;
  skeleton?: boolean;
  fontSize?: number;
  wrapperSize?: number;
}

export const Avatar: FC<AvatarProps> = ({ avatarUrl, backgroundColor, fallback, skeleton, fontSize = 36, wrapperSize = 84 }) => {
  const { value: isImageLoadingFailed, setTrue: setImageLoadingFailed, setFalse } = useBooleanState(false);
  const shouldDisplayFallback = !avatarUrl || isImageLoadingFailed;

  useEffect(() => {
    if (avatarUrl) {
      setFalse();
    }
  }, [avatarUrl]);

  if (skeleton) {
    return <Skeleton variant="avatar" width={`${wrapperSize}px`} height={`${wrapperSize}px`} borderRadius="50%" />;
  }

  return (
    <Wrapper style={{ backgroundColor }} wrapperSize={wrapperSize}>
      {shouldDisplayFallback ? (
        <Fallback data-testid="avatar-fallback" fontSize={fontSize}>{fallback}</Fallback>
      ) : (
        <AvatarImage
          src={avatarUrl}
          alt={`${fallback} avatar`}
          onError={setImageLoadingFailed}
          data-testid="avatar-image"
        />
      )}
    </Wrapper>
  );
};
