import { useMemo } from 'react';
import { HqOTableEnums } from '@hqo/react-components-library/dist/organisms/hqo-table';
import { TableColumnOptions } from '../use-table-columns.hook';

export type AddVisitButtonType = { addVisitLabel: string };

type BaseAddVisitColumnConfig = Omit<
  Partial<TableColumnOptions<AddVisitButtonType>>,
  'id' | 'columnType' | 'columnProps'
>;

const addVisitButtonInitialState: TableColumnOptions<AddVisitButtonType> = {
  id: 'addVisitLabel',
  weight: 3,
  maxWidth: '11rem',
  columnType: HqOTableEnums.ColumnTypesEnum.button,
};

export type AddVisitClickHandler<T extends AddVisitButtonType> = (data: T) => void;

export const useAddVisitColumn = <T extends AddVisitButtonType>(
  onClick: AddVisitClickHandler<T>,
  config?: BaseAddVisitColumnConfig,
): TableColumnOptions<T> =>
    useMemo(
      () => ({
        ...addVisitButtonInitialState,
        ...(config || {}),
        columnProps: {
          onClick,
        },
      }),
      [onClick, config],
    );
