import { buildingUuidSelector } from 'app/store/building/selectors';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { visitorGroupSelector, visitorGroupsLoadingSelector, visitorGroupsSelector } from 'app/store/visitor-groups/selectors';
import { VisitorGroup } from 'app/store/visitor-groups/types';
import { fetchVisitorGroup, fetchVisitorGroups } from 'app/store/visitor-groups/actions';

export const useLoadVisitorGroup = (uuid: string): [boolean, boolean] => {
  const dispatch = useDispatch();
  const buildingUuid = useSelector(buildingUuidSelector);
  const visitorGroups: VisitorGroup[] = useSelector(visitorGroupsSelector);
  const visitorGroupDetails: Record<string, VisitorGroup | null> = useSelector(visitorGroupSelector);
  const loading: boolean = useSelector(visitorGroupsLoadingSelector);
  const visitorGroup: VisitorGroup | null = visitorGroupDetails?.[uuid];

  useEffect(() => {
    if (visitorGroups.length === 0 && uuid && !loading) {
      dispatch(fetchVisitorGroups.request({ buildingUuid }));
    }
  }, [dispatch, buildingUuid, loading, uuid, visitorGroups.length]);

  useEffect(() => {
    if (visitorGroupDetails && !visitorGroup && !loading && uuid) {
      dispatch(fetchVisitorGroup.request({ visitorGroupUuid: uuid, buildingUuid }));
    }
  }, [uuid, visitorGroup, visitorGroupDetails, loading, dispatch, buildingUuid]);

  return [!!visitorGroup, loading];
};
