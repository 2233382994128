import { useFormikContext } from 'formik';
import { FC, useCallback } from 'react';
import { FormValues } from '../visitor-groups.interfaces';
import { useDeleteVisitor } from 'app/components/visits/visit-form/hooks';
import { VisitorDetails } from '../../shared/visitor-details/visitor-details';
import { AddMemberButton } from './add-member-button';
import {
  VisitorsContainer,
  VisitorDetailsConatiner,
} from './visitor-groups-modal.styles';

export const VisitorGroupsModalDetails: FC = () => {
  const { values } = useFormikContext<FormValues>();
  const handleVisitorDelete = useDeleteVisitor();

  const onVisitorDelete = useCallback((index: number) => () => {
    handleVisitorDelete(index);
  }, [handleVisitorDelete]);

  return (
    <VisitorsContainer>
      <VisitorDetailsConatiner>
        {values.visitors?.map((_, index) => (
          <VisitorDetails
          // only for adding/removing visitors, no reordering
          // eslint-disable-next-line react/no-array-index-key
            key={index}
            isDeleteAvailable={false}
            canEditVisitorDetails
            canChangeVisitVisibility={false}
            fieldNamePrefix={`visitors[${index}]`}
            handleVisitorDelete={onVisitorDelete(index)}
            showMoreOptions={false}
          />
        ))}
      </VisitorDetailsConatiner>
      <AddMemberButton />
    </VisitorsContainer>
  );
};
