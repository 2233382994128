import { createAsyncAction } from 'typesafe-actions';
import {
  FetchVisitorErrorDto,
  FetchVisitorParams,
  FetchVisitorSuccessDto,
  UpdateVisitorNamePayloadDto,
  UpdateVisitorNameSuccessDto,
  UpdateVisitorNameFailureDto,
} from './types';

export const fetchVisitor = createAsyncAction(
  'VISITOR/FETCH_REQUEST',
  'VISITOR/FETCH_SUCCESS',
  'VISITOR/FETCH_FAILURE',
)<FetchVisitorParams, FetchVisitorSuccessDto, FetchVisitorErrorDto>();

export const updateVisitorName = createAsyncAction(
  'VISITOR/UPDATE_NAME_REQUEST',
  'VISITOR/UPDATE_NAME_SUCCESS',
  'VISITOR/UPDATE_NAME_FAILURE',
)<UpdateVisitorNamePayloadDto, UpdateVisitorNameSuccessDto, UpdateVisitorNameFailureDto>();
