import { useField } from 'formik';
import { useMemo } from 'react';
import { VisitorType } from 'app/store/visitor/types';

export const useSelectedVisitorData = (fieldNamePrefix: string) => {
  const [{ value: type }] = useField<string>(`${fieldNamePrefix}.type`);
  const [{ value: uuid }] = useField<string>(`${fieldNamePrefix}.uuid`);

  return useMemo(
    () => type === VisitorType.USER || !!uuid,
    [type, uuid],
  );
};
