import { useMemo } from 'react';

export const initialVisitorValues = {
  phone: undefined,
  firstName: '',
  lastName: '',
  email: undefined,
};

export const useInitialVisitorsValue = () => useMemo(() => [initialVisitorValues], []);
