import { Time } from 'app/shared/types/time';
import { VisitorType } from 'app/store/visitor/types';

export interface VisitFormProps {
  onClose: VoidFunction;
}

export enum VisibilityEnum {
  HOST_AND_CREATOR = 'HOST_AND_CREATOR',
  PUBLIC = 'PUBLIC',
}

export interface Visitor {
  uuid?: string;
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  type?: VisitorType;
  visibility?: VisibilityEnum;
  contact_info_waived?: boolean;
}

export interface VisitDateTime {
  startDate: Date | null;
  startTime: Time | null;
  endTime: Time | null;
}

export interface FormValues {
  hostUuid?: string;
  host?: string;
  floor?: string;
  suite?: string;
  groupName?: string;
  groupUuid?: string;
  saveAsGroup: boolean;
  visitTypeId: number;
  endDate: Date | null;
  visitDateTimes: VisitDateTime[];
  visitors: Visitor[];
  description?: string | null;
}

export interface FormFeatureFlags {
  canAddVisitor: boolean;
  canSaveAsGroup: boolean;
  canEditVisitTypes: boolean;
  canEditVisitorDetails: boolean;
  canSelectMultipleDates: boolean;
  canEditHost: boolean;
}
