import { useMemo } from 'react';
import { TableColumnOptions } from '../use-table-columns.hook';

export type TextColumnConfig<T extends object> = Partial<TableColumnOptions<T>>;

export const useTextColumn = <T extends object>(id: keyof T, config?: TextColumnConfig<T>): TableColumnOptions<T> =>
  useMemo(
    () => ({
      id,
      weight: 1,
      ...(config || {}),
    }),
    [id, config],
  );
