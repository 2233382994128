import { Modal as HqOModal } from '@hqo/react-components-library/dist/molecules/modals/modal';
import { ZIndex } from 'app/shared/consts/z-index';
import styled from 'styled-components';

export const StyledModal = styled(HqOModal)`
  z-index: ${ZIndex.MODAL};

  .modal-paper {
    padding: 0%;
    width: 90%;
    height: 90%;
    background-color: ${({ theme }) => theme.colors.palette.systemGreyLight};

    display: flex;
    flex-direction: column;
  }
`;

export const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: row;
  padding: 20px 24px;
  gap: 20px;
  background-color: ${({ theme }) => theme.colors.palette.sharedWhite};
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.palette.systemGreyLight};
`;

export const FooterWrapper = styled.div`
  padding: 20px 30px;
  background-color: ${({ theme }) => theme.colors.palette.sharedWhite};
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid ${({ theme }) => theme.colors.palette.systemGreyLight};
`;
