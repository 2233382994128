import { FC } from 'react';
import { Container, Icon, IconContainer, NoVisibilityIcon, StyledPanel } from './more-options.styles';
import { CheckboxHqo } from '@hqo/react-components-library/dist/molecules/checkbox-hqo';
import { useIntl } from 'react-intl';
import { VisibilityEnum } from 'app/components/visits/visit-form/visit-form.interfaces';
import { useMoreOptions } from './more-options.hook';
import type { UseMoreOptionsResponse } from './more-options.hook';

interface MoreOptionsProps {
  fieldNamePrefix: string;
  canChangeVisitVisibility?: boolean;
}

export const MoreOptions: FC<MoreOptionsProps> = ({
  fieldNamePrefix,
  canChangeVisitVisibility,
}: MoreOptionsProps): JSX.Element => {
  const intl = useIntl();
  const {
    canEditWaiveContactInfo,
    onVisibilityChange,
    onWaiveChange,
    ref,
    toggleVisibility,
    visible,
    visibilityValue,
    waiveValue,
  }: UseMoreOptionsResponse = useMoreOptions({
    fieldNamePrefix,
  });

  return (
    <Container ref={ref}>
      <IconContainer>
        {visibilityValue === VisibilityEnum.HOST_AND_CREATOR && (
          <NoVisibilityIcon className="material-icons-outlined" data-testid="visibility-off-icon">
            visibility_off
          </NoVisibilityIcon>
        )}
        <Icon onClick={toggleVisibility} className="material-icons-outlined" data-testid="more-options-icon">
          more_horiz
        </Icon>
      </IconContainer>
      {visible && (
        <StyledPanel depth={1} borderRadius="sm" data-testid="more-options-checkbox-container">
          {canEditWaiveContactInfo && (
            <CheckboxHqo
              checked={waiveValue}
              data-testid="waive-checkbox"
              label={intl.formatMessage({ id: 'visitForm.visitors.waiveLabel' })}
              onChange={onWaiveChange}
              subtitle={intl.formatMessage({ id: 'visitForm.visitors.waiveSubtitle' })}
            />
          )}
          <CheckboxHqo
            checked={visibilityValue === VisibilityEnum.HOST_AND_CREATOR}
            data-testid="visibility-checkbox"
            label={intl.formatMessage({ id: 'visitForm.visitors.vipLabel' })}
            onChange={onVisibilityChange}
            disabled={!canChangeVisitVisibility}
            subtitle={intl.formatMessage({ id: 'visitForm.visitors.vipSubtitle' })}
          />
        </StyledPanel>
      )}
    </Container>
  );
};
