import { Loader } from 'app/components/loader';
import { VisitCreationForm } from 'app/components/visits/visit-creation-form';
import { FC, lazy, Suspense } from 'react';
import { CREATE_ROUTE_QUERY_PARAM } from 'app/components/visits/visit-modal/visit-modal.consts';
import { useQueryParam } from 'app/shared/hooks/use-query-param';
import { useLoadVisitorGroup } from 'app/components/visits/hooks/use-load-visitor-group.hook';

const VisitModal = lazy(() => import('app/components/visits/visit-modal/visit-modal.container'));

export const CreateVisitPage: FC = () => {
  const [groupUuid] = useQueryParam(CREATE_ROUTE_QUERY_PARAM);
  const [isVisitorGroupLoaded, isLoadingVisitorGroups] = useLoadVisitorGroup(groupUuid);

  return (
    <Suspense fallback={<Loader testId="create-visit-form-loader" />}>
      <VisitModal
        FormComponent={VisitCreationForm}
        isLoading={(!isVisitorGroupLoaded && isLoadingVisitorGroups) || isLoadingVisitorGroups}
      />
    </Suspense>
  );
};
