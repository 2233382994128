import { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { FormValues } from '../visit-form.interfaces';
import { initialVisitorValues } from './creation/use-initial-visitors-value.hook';

export const useResetVisitor = () => {
  const { values, setTouched, touched, setFieldValue } = useFormikContext<FormValues>();

  const resetVisitorValues = useCallback((index: number) => {
    const newVisitors = [...values.visitors];
    if (newVisitors[index]) {
      newVisitors[index] = { ...initialVisitorValues };
      setFieldValue('visitors', newVisitors);
    }
  }, [values, setFieldValue]);

  const resetGroupValues = useCallback(() => {
    setFieldValue('groupUuid', undefined);
    setFieldValue('groupName', '');
  }, [setFieldValue]);

  const deleteVisitorTouched = useCallback((index: number) => {
    if (!touched.visitors) {
      return;
    }

    const updatedTouched = [...touched.visitors];
    updatedTouched.splice(index, 1);
    setTouched({ ...touched, visitors: updatedTouched });
  }, [touched, setTouched]);

  const handleResetVisitor = useCallback((index: number) => () => {
    resetVisitorValues(index);
    deleteVisitorTouched(index);
    resetGroupValues();
  }, [resetVisitorValues, deleteVisitorTouched, resetGroupValues]);

  return handleResetVisitor;
};
