import { FormValues } from '../../visitor-groups.interfaces';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { CreateUpdateVisitorGroupBody } from 'app/store/visitor-groups/types';
import type { Visitor } from '../../visitor-groups.interfaces';
import { createVisitorGroup } from 'app/store/visitor-groups/actions';
import { buildingUuidSelector } from 'app/store/building/selectors';

const mapFormValuesToDto = (values: FormValues): CreateUpdateVisitorGroupBody => ({
  visitors: values.visitors.map((visitor: Visitor) => ({
    uuid: visitor?.uuid,
    firstName: visitor.firstName,
    lastName: visitor.lastName,
    email: visitor.email === '' ? undefined : visitor.email,
    phone: visitor.phone === '' ? undefined : visitor.phone,
  })),
  name: values.groupName,
});

export const useFormSubmitHandler = () => {
  const dispatch = useDispatch();
  const buildingUuid: string = useSelector(buildingUuidSelector);

  const handleSubmit = useCallback(
    (values: FormValues) => {
      const data = mapFormValuesToDto(values);

      dispatch(createVisitorGroup.request({ buildingUuid, ...data }));
    },
    [buildingUuid, dispatch],
  );

  return { handleSubmit };
};
