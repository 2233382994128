import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { firstValueFrom } from 'rxjs';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { useDebounce } from 'use-debounce';
import { apiClient as apiClientFactory, ApiClient } from 'app/store/api-client';
import { FormValues } from 'app/components/visits/visit-form/visit-form.interfaces';

const DEBOUNCE_TIME = 300;

const testUniqueGroupName = async (groupName: string, apiClient: ApiClient): Promise<boolean> => {
  try {
    const { data } = await firstValueFrom(apiClient.getVisitorGroups({ query: groupName }));
    return !data.some(({ name }) => name?.toLowerCase() === groupName);
  } catch (e) {
    return false;
  }
};

export const useUniqueGroupNameValidation = () => {
  const { formatMessage } = useIntl();
  const { dirty, values, setFieldError } = useFormikContext<FormValues>();
  const apiClient: ApiClient = useSelector(apiClientFactory);

  const [debouncedGroupName] = useDebounce(values.groupName, DEBOUNCE_TIME);

  const testGroupName = useCallback((groupName: string) => {
    testUniqueGroupName(groupName, apiClient).then((isUnique) => {
      if (!isUnique) {
        setFieldError('groupName', formatMessage({ id: 'errors.groupNameAlreadyExists' }));
      } else {
        setFieldError('groupName', undefined);
      }
    });
  }, [apiClient, setFieldError, formatMessage]);

  useEffect(() => {
    const trimmedGroupName = (debouncedGroupName ?? '').trim().toLowerCase();
    if (values.groupName && trimmedGroupName && !values.groupUuid) {
      testGroupName(trimmedGroupName);
    } else if (values.groupName && trimmedGroupName && dirty && values.groupUuid) {
      testGroupName(trimmedGroupName);
    }
  }, [apiClient, debouncedGroupName, dirty, values.groupName, values.groupUuid, setFieldError]);
};
