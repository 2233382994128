import { FooterWrapper, HeaderWrapper, StyledModal } from './modal.styles';
import { BACK_ROUTE_QUERY_PARAM } from 'app/components/visits/visit-modal/visit-modal.consts';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useQueryParam } from 'app/shared/hooks/use-query-param';

interface Props {
  basePath: string;
  children: React.ReactNode;
  dataTestId: string;
  onClose?: VoidFunction;
}

export const Modal = ({ basePath, children, dataTestId, onClose }: Props) => {
  const navigate = useNavigate();
  const [backRoute] = useQueryParam(BACK_ROUTE_QUERY_PARAM);
  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
      return;
    }
    navigate(backRoute ? decodeURIComponent(backRoute) : basePath, { replace: true });
  }, [navigate, basePath, backRoute, onClose]);

  return (
    <StyledModal opened dataTestId={dataTestId} onClose={handleClose}>
      {children}
    </StyledModal>
  );
};

Modal.Header = HeaderWrapper;

Modal.Footer = FooterWrapper;
