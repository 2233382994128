import { ActionType, createReducer } from 'typesafe-actions';
import { VisitorGroupsState, BuildingVisitorGroupsState } from './types';
import { addVisitor, createVisitorGroup, fetchVisitorGroup, fetchVisitorGroups, updateVisitorGroupName } from './actions';
import { ACTION_STATUSES } from 'app/shared/consts';

export const initialState: VisitorGroupsState = {};
export const initialVisitorGroupsState: BuildingVisitorGroupsState = {
  visitorGroups: null,
  total: 0,
  offset: 0,
  limit: 10,
  loading: false,
  error: null,
  fetchStatus: null,
  visitorGroupDetails: {},
  updateVisitorGroupNameRequest: {
    loading: false,
    error: null,
  },
  createVisitorGroupRequest: {
    loading: false,
    error: null,
  },
  addVisitorRequest: {
    loading: false,
    error: null,
  },
};

const handleFetchVisitorGroupsRequest = (
  state: VisitorGroupsState,
  action: ActionType<typeof fetchVisitorGroups.request>,
): VisitorGroupsState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialVisitorGroupsState,
    ...state[action.payload.buildingUuid],
    loading: true,
    fetchStatus: ACTION_STATUSES.PENDING,
  },
});

const handleFetchVisitorGroupsSuccess = (
  state: VisitorGroupsState,
  { payload }: ActionType<typeof fetchVisitorGroups.success>,
): VisitorGroupsState => {
  const oldGroups = payload.params.shouldReset ? [] : state[payload.params.buildingUuid].visitorGroups ?? [];
  return ({
    ...state,
    [payload.params.buildingUuid]: {
      ...state[payload.params.buildingUuid],
      loading: false,
      fetchStatus: ACTION_STATUSES.FULFILLED,
      visitorGroups: [...oldGroups, ...payload.response.data],
      total: payload.response.total,
      offset: payload.response.offset,
      limit: payload.response.limit,
      error: null,
    },
  });
};

const handleFetchVisitorGroupsFailure = (
  state: VisitorGroupsState,
  action: ActionType<typeof fetchVisitorGroups.failure>,
): VisitorGroupsState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    loading: false,
    fetchStatus: ACTION_STATUSES.REJECTED,
    error: action.payload.response,
  },
});

const handleFetchVisitorGroupRequest = (
  state: VisitorGroupsState,
  action: ActionType<typeof fetchVisitorGroup.request>,
): VisitorGroupsState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...initialVisitorGroupsState,
    ...state[action.payload.buildingUuid],
    loading: true,
  },
});

const handleFetchVisitorGroupSuccess = (
  state: VisitorGroupsState,
  { payload }: ActionType<typeof fetchVisitorGroup.success>,
): VisitorGroupsState => ({
  ...state,
  [payload.params.buildingUuid]: {
    ...state[payload.params.buildingUuid],
    loading: false,
    visitorGroupDetails: {
      ...state[payload.params.buildingUuid].visitorGroupDetails,
      [payload.params.visitorGroupUuid]: payload.response.data,
    },
  },
});

const handleFetchVisitorGroupFailure = (
  state: VisitorGroupsState,
  action: ActionType<typeof fetchVisitorGroup.failure>,
): VisitorGroupsState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    loading: false,
    error: action.payload.response,
  },
});

const handleCreateVisitorGroupRequest = (
  state: VisitorGroupsState,
  action: ActionType<typeof createVisitorGroup.request>,
): VisitorGroupsState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...state[action.payload.buildingUuid],
    createVisitorGroupRequest: {
      loading: true,
      error: null,
    },
  },
});

const handleCreateVisitorGroupSuccess = (
  state: VisitorGroupsState,
  { payload }: ActionType<typeof createVisitorGroup.success>,
): VisitorGroupsState => ({
  ...state,
  [payload.params.buildingUuid]: {
    ...state[payload.params.buildingUuid],
    createVisitorGroupRequest: {
      loading: false,
      error: null,
    },
  },
});

const handleCreateVisitorGroupFailure = (
  state: VisitorGroupsState,
  { payload }: ActionType<typeof createVisitorGroup.failure>,
): VisitorGroupsState => ({
  ...state,
  [payload.params.buildingUuid]: {
    ...state[payload.params.buildingUuid],
    createVisitorGroupRequest: {
      loading: false,
      error: payload.error,
    },
  },
});

const handleUpdateVisitorGroupNameRequest = (
  state: VisitorGroupsState,
  action: ActionType<typeof updateVisitorGroupName.request>,
): VisitorGroupsState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...state[action.payload.buildingUuid],
    updateVisitorGroupNameRequest: {
      loading: true,
      error: null,
    },
  },
});

const handleUpdateVisitorGroupNameSuccess = (
  state: VisitorGroupsState,
  { payload }: ActionType<typeof updateVisitorGroupName.success>,
): VisitorGroupsState => ({
  ...state,
  [payload.params.buildingUuid]: {
    ...state[payload.params.buildingUuid],
    updateVisitorGroupNameRequest: {
      loading: false,
      error: null,
    },
  },
});

const handleUpdateVisitorGroupNameFailure = (
  state: VisitorGroupsState,
  action: ActionType<typeof updateVisitorGroupName.failure>,
): VisitorGroupsState => ({
  ...state,
  [action.payload.params.buildingUuid]: {
    ...state[action.payload.params.buildingUuid],
    updateVisitorGroupNameRequest: {
      loading: false,
      error: action.payload.response,
    },
  },
});

const handleAddVisitorRequest = (
  state: VisitorGroupsState,
  action: ActionType<typeof addVisitor.request>,
): VisitorGroupsState => ({
  ...state,
  [action.payload.buildingUuid]: {
    ...state[action.payload.buildingUuid],
    addVisitorRequest: {
      loading: true,
      error: null,
    },
  },
});

const handleAddVisitorSuccess = (
  state: VisitorGroupsState,
  { payload }: ActionType<typeof addVisitor.success>,
): VisitorGroupsState => ({
  ...state,
  [payload.params.buildingUuid]: {
    ...state[payload.params.buildingUuid],
    visitorGroupDetails: {
      ...state[payload.params.buildingUuid]?.visitorGroupDetails,
      [payload.params.visitorGroupUuid]: {
        ...state[payload.params.buildingUuid]?.visitorGroupDetails[payload.params.visitorGroupUuid],
        visitors: [
          payload.response.data,
          ...(state[payload.params.buildingUuid]?.visitorGroupDetails[payload.params.visitorGroupUuid]?.visitors ?? []),
        ],
      },
    },
    addVisitorRequest: {
      loading: false,
      error: null,
    },
  },
});

const handleAddVisitorFailure = (
  state: VisitorGroupsState,
  { payload }: ActionType<typeof addVisitor.failure>,
): VisitorGroupsState => ({
  ...state,
  [payload.params.buildingUuid]: {
    ...state[payload.params.buildingUuid],
    addVisitorRequest: {
      loading: false,
      error: payload.response,
    },
  },
});

export const visitorGroupsReducer = createReducer(initialState)
  .handleAction(fetchVisitorGroups.request, handleFetchVisitorGroupsRequest)
  .handleAction(fetchVisitorGroups.success, handleFetchVisitorGroupsSuccess)
  .handleAction(fetchVisitorGroups.failure, handleFetchVisitorGroupsFailure)

  .handleAction(fetchVisitorGroup.request, handleFetchVisitorGroupRequest)
  .handleAction(fetchVisitorGroup.success, handleFetchVisitorGroupSuccess)
  .handleAction(fetchVisitorGroup.failure, handleFetchVisitorGroupFailure)

  .handleAction(updateVisitorGroupName.request, handleUpdateVisitorGroupNameRequest)
  .handleAction(updateVisitorGroupName.success, handleUpdateVisitorGroupNameSuccess)
  .handleAction(updateVisitorGroupName.failure, handleUpdateVisitorGroupNameFailure)

  .handleAction(createVisitorGroup.request, handleCreateVisitorGroupRequest)
  .handleAction(createVisitorGroup.success, handleCreateVisitorGroupSuccess)
  .handleAction(createVisitorGroup.failure, handleCreateVisitorGroupFailure)

  .handleAction(addVisitor.request, handleAddVisitorRequest)
  .handleAction(addVisitor.success, handleAddVisitorSuccess)
  .handleAction(addVisitor.failure, handleAddVisitorFailure);
