import { FC } from 'react';
import { VisitModalFooter } from './visitor-groups-modal.styles';
import { useIntl } from 'react-intl';
import { Button } from '@hqo/react-components-library/dist/atoms/button/button';

interface Props {
  handleClose: VoidFunction;
  submitForm: VoidFunction;
}

export const VisitorGroupsModalFooter: FC<Props> = ({ handleClose, submitForm }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <VisitModalFooter>
      <Button
        text={formatMessage({ id: 'buttons.cancel' })}
        variant="Plain"
        onClick={handleClose}
        data-testid="cancel-button"
      />
      <Button
        text={formatMessage({ id: 'tables.visitorGroups.visitorGroup.addToGroup' })}
        variant="Primary"
        onClick={submitForm}
        data-testid="submit-visitor-group-button"
      />
    </VisitModalFooter>
  );
};
