import { FC, useCallback } from 'react';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { FormValues } from '../visitor-groups.interfaces';
import { LinkButton } from './visitor-groups-modal.styles';
import { initialVisitorValues } from './hooks/use-initial-visitors-value.hook';

export const AddMemberButton: FC = () => {
  const { formatMessage } = useIntl();
  const { setValues, values } = useFormikContext<FormValues>();
  const addVisitor = useCallback(() => {
    setValues({ ...values, visitors: [...values.visitors, initialVisitorValues] });
  }, [setValues, values]);

  return (
    <LinkButton
      variant="Plain"
      onClick={addVisitor}
      Icon={<span className="material-icons">add</span>}
      iconDirection="left"
      text={formatMessage({ id: 'tables.visitorGroups.visitorGroup.addMember' })}
      data-testid="add-visitor-button"
    />
  );
};
