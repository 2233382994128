import { Loader } from 'app/components/loader';
import { FC, lazy, Suspense } from 'react';

const AddWatchlistPersonForm = lazy(() => import('app/components/add-watchlist-person/add-watchlist-person-form'));

export const AddWatchlistPersonPage: FC = () => (
  <Suspense fallback={<Loader testId="add-watchlist-person-loader" />}>
    <AddWatchlistPersonForm />
  </Suspense>
);
