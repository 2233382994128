import {
  BulkCreateVisitsBody,
  CancelVisitSuccessDto,
  CreateVisitsOperationErrorDto,
  CreateVisitsOperationParams,
  CreateVisitsOperationSuccessDto,
  CurrentBuildingVisitOperationParams,
  FetchVisitForBuildingErrorDto,
  FetchVisitForBuildingParams,
  FetchVisitForBuildingSuccessDto,
  FetchVisitsForBuildingErrorDto,
  FetchVisitsForBuildingParams,
  FetchVisitsForBuildingSuccessDto,
  NotifyHostSuccessDto,
  SearchVisitByCredentialErrorDto,
  SearchVisitByCredentialParams,
  SearchVisitByCredentialSuccessDto,
  UpdateVisitDtoWithUuid,
  VisitOperationErrorDto,
  VisitOperationParams,
  VisitOperationSuccessDto,
  VisitUpdatedEvent,
  VisitUpdatedForView,
} from 'app/store/visits/types';
import { createAction, createAsyncAction } from 'typesafe-actions';
import { UpdateVisitOperationParams, UpdateVisitOperationSuccessDto, UpdateVisitOperationErrorDto } from './types';

export const fetchVisits = createAsyncAction('VISITS/FETCH_REQUEST', 'VISITS/FETCH_SUCCESS', 'VISITS/FETCH_FAILURE')<
  FetchVisitsForBuildingParams,
  FetchVisitsForBuildingSuccessDto,
  FetchVisitsForBuildingErrorDto
>();

export const fetchVisit = createAsyncAction(
  'VISITS/FETCH_ONE_REQUEST',
  'VISITS/FETCH_ONE_SUCCESS',
  'VISITS/FETCH_ONE_FAILURE',
)<FetchVisitForBuildingParams, FetchVisitForBuildingSuccessDto, FetchVisitForBuildingErrorDto>();

export const checkInVisitForCurrentBuilding = createAction('VISITS/CHECK_IN_FOR_CURRENT_BUILDING')<
  Pick<VisitOperationParams, 'visitUuid'>
>();

export const checkInVisit = createAsyncAction(
  'VISITS/CHECK_IN_REQUEST',
  'VISITS/CHECK_IN_SUCCESS',
  'VISITS/CHECK_IN_FAILURE',
)<VisitOperationParams, VisitOperationSuccessDto, VisitOperationErrorDto>();

export const revertVisitStatus = createAsyncAction(
  'VISITS/REVERT_STATUS_REQUEST',
  'VISITS/REVERT_STATUS_SUCCESS',
  'VISITS/REVERT_STATUS_FAILURE',
)<VisitOperationParams, VisitOperationSuccessDto, VisitOperationErrorDto>();

export const checkOutVisitForCurrentBuilding = createAction('VISITS/CHECK_OUT_FOR_CURRENT_BUILDING')<
  Pick<VisitOperationParams, 'visitUuid'>
>();

export const checkOutVisit = createAsyncAction(
  'VISITS/CHECK_OUT_REQUEST',
  'VISITS/CHECK_OUT_SUCCESS',
  'VISITS/CHECK_OUT_FAILURE',
)<VisitOperationParams, VisitOperationSuccessDto, VisitOperationErrorDto>();

export const cancelVisitForCurrentBuilding = createAction('VISITS/CANCEL_FOR_CURRENT_BUILDING')<
  Pick<VisitOperationParams, 'visitUuid'>
>();

export const notifyHostVisit = createAsyncAction(
  'VISITS/NOTIFY_HOST_REQUEST',
  'VISITS/NOTIFY_HOST_SUCCESS',
  'VISITS/NOTIFY_HOST_FAILURE',
)<Pick<CurrentBuildingVisitOperationParams, 'visitUuid'>, NotifyHostSuccessDto, VisitOperationErrorDto>();

export const cancelVisit = createAsyncAction('VISITS/CANCEL_REQUEST', 'VISITS/CANCEL_SUCCESS', 'VISITS/CANCEL_FAILURE')<
  VisitOperationParams,
  CancelVisitSuccessDto,
  VisitOperationErrorDto
>();

export const createVisitsForCurrentBuilding = createAction('VISITS/CREATE_FOR_CURRENT_BUILDING')<BulkCreateVisitsBody>();

export const createVisits = createAsyncAction(
  'VISITS/CREATE_REQUEST',
  'VISITS/CREATE_SUCCESS',
  'VISITS/CREATE_FAILURE',
)<CreateVisitsOperationParams, CreateVisitsOperationSuccessDto, CreateVisitsOperationErrorDto>();

export const updateVisitForCurrentBuilding = createAction(
  'VISITS/UPDATE_FOR_CURRENT_BUILDING',
)<UpdateVisitDtoWithUuid>();

export const updateVisit = createAsyncAction('VISITS/UPDATE_REQUEST', 'VISITS/UPDATE_SUCCESS', 'VISITS/UPDATE_FAILURE')<
  UpdateVisitOperationParams,
  UpdateVisitOperationSuccessDto,
  UpdateVisitOperationErrorDto
>();

export const visitUpdatedEvent = createAction('VISITS/UPDATED_EVENT')<VisitUpdatedEvent>();

export const visitUpdatedEventForCurrentView = createAction('VISITS/UPDATED_FOR_CURRENT_VIEW')<VisitUpdatedForView>();

export const searchVisitByCredential = createAsyncAction(
  'VISTS/SEARCH_BY_CREDENTIAL_REQUEST',
  'VISTS/SEARCH_BY_CREDENTIAL_SUCCESS',
  'VISTS/SEARCH_BY_CREDENTIAL_FAILURE',
)<SearchVisitByCredentialParams, SearchVisitByCredentialSuccessDto, SearchVisitByCredentialErrorDto>();
