import { useIntl } from 'react-intl';
import { RadioButtons } from 'app/components/fields/radio-buttons/radio-buttons';
import { useVisitTypes } from './visit-details.hooks';
import { FC } from 'react';
import { DateAndTime } from './date-and-time';
import { StyledPanel, Title } from './visit-details.styles';
import { FormFeatureFlags, FormValues, Visitor } from 'app/components/visits/visit-form/visit-form.interfaces';
import { SearchHost } from 'app/components/visits/visit-form/visit-form-content/visit-details/search-host/search-host';
import { FormInput } from 'app/components/fields/form-input';
import { useFieldMetadata } from 'app/store/ui-metadata/hooks';
import { useUserPermissionCheck } from 'app/store/user-permissions/hooks';
import { Permission } from 'app/store/user-permissions/types';
import { Note } from './note/note';
import { FeatureEnablementFlagEnum } from 'app/store/ui-metadata/types';
import { useFormikContext } from 'formik';

interface VisitDetails extends Pick<FormFeatureFlags, 'canEditVisitTypes' | 'canSelectMultipleDates' | 'canEditHost'> {
  shouldReplaceInitialDate?: boolean;
}

export const VisitDetails: FC<VisitDetails> = ({
  canEditVisitTypes,
  canSelectMultipleDates,
  canEditHost,
  shouldReplaceInitialDate,
}) => {
  const { values } = useFormikContext<FormValues>();
  const { formatMessage } = useIntl();
  const visitTypeButtons = useVisitTypes();
  const hasAccessToChangeHost = useUserPermissionCheck(Permission.CHANGE_VISITOR_HOST);
  const altHostsEnabled = useFieldMetadata('alt_hosts_enabled');
  const renderSearchHost = canEditHost && hasAccessToChangeHost && altHostsEnabled;
  const notesEnabled = useFieldMetadata('checkin_instructions');
  const showNotesField = [FeatureEnablementFlagEnum.ENABLED_REQUIRED, FeatureEnablementFlagEnum.ENABLED_OPTIONAL].includes(notesEnabled as FeatureEnablementFlagEnum);
  const radioButtonsDisabled = !canEditVisitTypes || values.visitors.some((visitor: Visitor) => visitor.contact_info_waived);

  return (
    <StyledPanel>
      <Title>{formatMessage({ id: 'visitForm.visitDetails.title' })}</Title>
      {renderSearchHost ? <SearchHost /> : <FormInput fieldName="host" translationKey="host" disabled required />}
      <RadioButtons fieldName="visitTypeId" buttons={visitTypeButtons} disabled={radioButtonsDisabled} />
      {showNotesField && <Note />}
      <DateAndTime
        canSelectMultipleDates={canSelectMultipleDates}
        shouldReplaceInitialDate={shouldReplaceInitialDate}
      />
    </StyledPanel>
  );
};
