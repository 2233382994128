import { apiClient } from 'app/store/api-client';
import { buildingSettingsByBuildingState } from 'app/store/building-settings/selectors';
import { buildingSelector } from 'app/store/building/selectors';
import { configSelector } from 'app/store/config/selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useClient = () => {
  const config = useSelector(configSelector);
  const building = useSelector(buildingSelector);
  const buildingSettings = useSelector(buildingSettingsByBuildingState);
  const client = useMemo(() => apiClient({ config, building, buildingSettings }), [config, building]);

  return client;
};
