import { useCallback } from 'react';
import { useFormikContext } from 'formik';

export const useSkipValidationOnBlur = (
  fieldName: string,
  fieldValue: string,
  callback?: React.FocusEventHandler<HTMLInputElement>,
): React.FocusEventHandler<HTMLInputElement> => {
  const { setFieldTouched } = useFormikContext();
  const hasValue = !!fieldValue;

  return useCallback((event) => {
    callback?.(event);
    setFieldTouched(fieldName, hasValue, false);
  }, [setFieldTouched, fieldName, hasValue]);
};
