import { FC } from 'react';
import { IconWrapper } from './delete-visitor.styles';

interface DeleteProps {
  handleVisitorDelete?: VoidFunction;
}

export const DeleteVisitor: FC<DeleteProps> = ({ handleVisitorDelete }) => (
  <IconWrapper onClick={handleVisitorDelete} data-testid="delete-visitor">
    <span className="material-icons">delete</span>
  </IconWrapper>
);
