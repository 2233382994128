import { Loader } from 'app/components/loader';
import { FC, lazy, Suspense } from 'react';

const ManageDayPasses = lazy(() => import('app/components/manage-day-passes/manage-day-passes.container'));

export const ManageDayPassesPage: FC = () => (
  <Suspense fallback={<Loader testId="manage-day-passes-loader" />}>
    <ManageDayPasses />
  </Suspense>
);
