import format from 'date-fns/format';
import isBefore from 'date-fns/isBefore';
import { utcToZonedTime } from 'date-fns-tz';
import qs from 'qs';
import { FormValues, VisitDateTime, Visitor } from 'app/components/visits/visit-form/visit-form.interfaces';
import { VisitRowData } from 'app/components/visits/visits-table/visits-table.interfaces';
import { Time } from 'app/shared/types/time';

export const VISITORS_INITIAL_VALUES_QUERY_STRING_KEY = 'visitors';

export function getVisitorsInitialValueQueryString(visitors: Visitor[]): string {
  return qs.stringify(
    { [VISITORS_INITIAL_VALUES_QUERY_STRING_KEY]: visitors },
    { addQueryPrefix: true, arrayFormat: 'brackets' },
  );
}

const visitRowDataToFormValues = (data: VisitRowData, timeZone: string): Partial<FormValues> => {
  let visitDateTimes: VisitDateTime[] | undefined;
  if (data.endDate && data.startDate && isBefore(utcToZonedTime(new Date(), timeZone), data.startDate)) {
    visitDateTimes = [{
      startDate: utcToZonedTime(data.startDate, timeZone),
      startTime: format(utcToZonedTime(data.startDate, timeZone), 'HH:mm') as Time,
      endTime: format(utcToZonedTime(data.endDate, timeZone), 'HH:mm') as Time,
    }];
  }

  return Object.entries({
    hostUuid: data.hostUuid,
    host: data.host,
    floor: data.floor,
    suite: data.suite,
    visitTypeId: data.visitTypeId,
    visitDateTimes,
    visitors: [{
      uuid: data.visitorUuid,
      email: data.visitorEmail,
      phone: data.visitorPhone,
      firstName: data.visitorFirstName,
      lastName: data.visitorLastName,
      visibility: data.visibility,
    }],
    description: data.description,
  })
    .filter(([_, value]) => !!value)
    .reduce((values, [key, value]) => ({ ...values, [key]: value }), {} as Partial<FormValues>);
};

export function getVisitFormValuesQueryString(data: VisitRowData, timeZone: string): string {
  return qs.stringify(
    { ...visitRowDataToFormValues(data, timeZone) },
    { addQueryPrefix: true, arrayFormat: 'brackets' },
  );
}
