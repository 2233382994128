import { FC } from 'react';
import { useLocation } from 'react-router';

import { Description, HeaderWrapper, InfoSection, Name, NameRow } from './visitor-groups-modal.styles';
import { Avatar } from 'app/components/avatar';
import { colors } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { Skeleton } from '@hqo/react-components-library/dist/atoms/skeleton';
import { useIntl } from 'react-intl';
import { EditableTextField } from '@hqo/react-components-library/dist/atoms/editable-text-field';
import type { FormValues, UseVisitorGroupModalHeaderResponse } from '../visitor-groups.interfaces';
import { useVisitorGroupModalHeader } from './hooks/use-visitor-group-modal-header';
import { useFormikContext } from 'formik';
import { GROUPS_CREATE_ROUTE, GROUPS_ROUTE } from 'app/shared/consts';

interface Props {
  name: string;
  skeleton: boolean;
  shouldSubmitOnBlur?: boolean;
}

export const VisitorGroupsModalHeader: FC<Props> = ({ name, skeleton, shouldSubmitOnBlur = false }: Props) => {
  const { formatMessage } = useIntl();
  const { errors, submitCount } = useFormikContext<FormValues>();
  const { pathname } = useLocation();

  const {
    hasEditPermissions,
    initials,
    inputValue,
    nameExists,
    onBlur,
    onSetInputValue,
    setValue,
    value,
  }: UseVisitorGroupModalHeaderResponse = useVisitorGroupModalHeader(name, shouldSubmitOnBlur);

  const isInputEditable = !name || name === 'G' || pathname === `${GROUPS_ROUTE}/${GROUPS_CREATE_ROUTE}`;
  const isNameErrored = submitCount > 0 && errors?.groupName !== undefined;
  const errorText = nameExists
    ? formatMessage({ id: 'tables.visitorGroups.visitorGroup.nameExists' })
    : formatMessage({ id: 'tables.visitorGroups.visitorGroup.nameBlank' });

  return (
    <HeaderWrapper data-testid="visitor-group-modal-header">
      <Avatar fallback={initials} avatarUrl={undefined} backgroundColor={colors.palette.systemGreenAlt} skeleton={skeleton} />
      <InfoSection>
        <NameRow>
          {skeleton ? (
            <Name>
              <Skeleton variant="text" width="200px" height="1em" />
            </Name>
          ) : (
            <EditableTextField
              hasEditPermissions={hasEditPermissions}
              isInputEditable={isInputEditable}
              inputValue={inputValue}
              isErrored={isNameErrored}
              errorText={errorText}
              onBlur={onBlur}
              setInputValue={onSetInputValue}
              setValue={setValue}
              value={value}
              placeholder={formatMessage({ id: 'fields.groupName.placeholder' })}
            />
          )}
        </NameRow>
        <Description>
          {
            skeleton
              ? <Skeleton variant="text" width="300px" height="1em" />
              : formatMessage({ id: 'tables.visitorGroups.visitorGroup.description' })
          }
        </Description>
      </InfoSection>
    </HeaderWrapper>
  );
};
