import styled from 'styled-components';
import { BaseTable } from 'app/components/base-table';
import { Button } from '@hqo/react-components-library/dist/atoms/button';

export const VisitorGroupDetailsBaseTable = styled(BaseTable)`
  padding: 30px;
  min-height: 104px;
  height: auto;
  margin-bottom: 0 !important;

  [data-testid='hqo-table-header'] {
    display: none;
  }
`;

export const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const InfoSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const NameRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
`;

export const Name = styled.h1`
  font-family: ${({ theme }) => theme.fonts.join()};
  font-size: 24px;
  line-height: 28px;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.palette.systemBlack};
  margin: 0;
`;

export const Description = styled.p`
  font-family: ${({ theme }) => theme.fonts.join()};
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
  margin: 0;
  margin-bottom: auto;
`;

export const VisitorGroupDetailsDeleteCellWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding: 12px 16px;
`;

export const LinkButton = styled(Button)`
  padding: 0;
  color: ${({ theme }) => theme.colors.palette.systemBlue};
  height: 32px;
  margin-left: 30px;
  margin-bottom: 30px;

  .button-content + span {
    margin-left: 0;
  }
`;

export const VisitorDetailsConatiner = styled.div`
  padding: 30px 30px 0;
`;

export const VisitModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

export const VisitorsContainer = styled.div`
  height: 416px;
  overflow-y: auto;
`;
