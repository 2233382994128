import { colors } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { BulkAction, HqOTableFilter } from '@hqo/react-components-library/dist/organisms/hqo-table/types';
import { Table } from 'app/components/base-table/base-table.styles';
import { PaginationOptions, usePaginationOptions } from 'app/components/base-table/use-pagination-options.hook';
import { TableColumnOptions, useTableColumns } from 'app/components/base-table/use-table-columns.hook';
import { useTableData } from 'app/components/base-table/use-table-data.hook';
import { DEFAULT_TABLE_LIMIT } from 'app/shared/consts/base-table';
import { useIntl } from 'react-intl';
import { MessageIds } from '../../../i18n';
import { useBulkProps } from './use-bulk-props.hook';
import { ButtonProps } from '@hqo/react-components-library/dist/atoms/button';
import { ReactNode } from 'react';

interface BaseVisitsTableProps<TData, TColumns = never> extends PaginationOptions {
  className?: string;
  tableTranslationsKeyPrefix: string;
  columns: TableColumnOptions<TData, TColumns>[];
  filters?: HqOTableFilter[];
  data: TData[];
  isLoading: boolean;
  searchQuery?: string;
  searchRightIcon?: ReactNode;
  skeletonRowsLimit?: number;
  onSearchQueryChange?: (searchQuery: string) => void;
  minWidth?: string;
  bulkActions?: BulkAction[];
  headerButtons?: ButtonProps[];
  highlightedRowIds?: number[];
}

export const BaseTable = <TData extends object, TColumns extends string = never>({
  className,
  tableTranslationsKeyPrefix,
  columns,
  filters,
  data,
  isLoading,
  offset,
  onPageChange,
  page,
  total,
  limit,
  searchQuery,
  searchRightIcon,
  skeletonRowsLimit,
  onSearchQueryChange: handleSearchQueryChange,
  minWidth,
  bulkActions,
  headerButtons,
  highlightedRowIds,
}: BaseVisitsTableProps<TData, TColumns>): JSX.Element => {
  const tableColumns = useTableColumns(tableTranslationsKeyPrefix, columns);
  const paginationProps = usePaginationOptions(tableTranslationsKeyPrefix, {
    offset,
    onPageChange,
    page,
    total,
    limit,
  });
  const tableData = useTableData<TData>(data);
  const { formatMessage } = useIntl();
  const isInitialLoading = isLoading && !data.length;
  const bulkActionsProps = useBulkProps(bulkActions, tableTranslationsKeyPrefix);

  return (
    <Table
      className={className}
      tableName={formatMessage({ id: `${tableTranslationsKeyPrefix}.title` as MessageIds })}
      columns={tableColumns}
      filters={filters}
      data={tableData}
      isLoading={isInitialLoading}
      paginationProps={paginationProps}
      version={2}
      tableHeaderBackground={colors.palette.systemGreyLight}
      searchValue={searchQuery}
      searchRightIcon={searchRightIcon}
      skeletonRowsLimit={skeletonRowsLimit ?? DEFAULT_TABLE_LIMIT}
      onSearch={handleSearchQueryChange}
      searchPlaceholderText={formatMessage({ id: `${tableTranslationsKeyPrefix}.searchPlaceholder` as MessageIds })}
      emptyStateTitle={formatMessage({ id: `${tableTranslationsKeyPrefix}.emptyTable.title` as MessageIds })}
      emptyStateSubtitle={formatMessage({ id: `${tableTranslationsKeyPrefix}.emptyTable.subTitle` as MessageIds })}
      minWidth={minWidth}
      bulkProps={bulkActionsProps}
      headerButtons={headerButtons}
      highlightedRowIds={highlightedRowIds}
    />
  );
};
