import { useSelector } from 'react-redux';
import { FormValues } from '../../visit-form.interfaces';
import { VisitRO } from 'app/store/visits/types';
// TODO: move to common place
import { formatTimeValue } from 'app/components/visits/visit-form/visit-form-content/visit-details/date-and-time/time-selector/date-and-time.hooks';
import { openedVisitSelector } from 'app/store/visits/selectors';
import { useVisitUuidParam } from 'app/components/visits/hooks/use-visit-uuid-param.hook';
import { timezoneSelector } from 'app/store/building/selectors';

const mapVisit = (visit: VisitRO, timezone?: string): FormValues => {
  const visitorName = visit.visitor_name?.split(' ');

  return {
    visitors: [
      {
        firstName: visitorName.at(0),
        lastName: visitorName.at(1),
        email: visit.email,
        phone: visit.phone || '',
        visibility: visit.visibility,
        contact_info_waived: visit.contact_info_waived ?? false,
      },
    ],
    saveAsGroup: false,
    host: visit.host_name,
    visitDateTimes: [
      {
        startTime: formatTimeValue(new Date(visit.arrival_time), timezone),
        startDate: new Date(visit.arrival_time),
        endTime: visit.departure_time ? formatTimeValue(new Date(visit.departure_time), timezone) : null,
      },
    ],
    visitTypeId: visit.visit_type_id ?? 0,
    endDate: null,
    description: visit?.description ?? null,
  };
};

export const useInitialValues = (): FormValues => {
  const visitUuid = useVisitUuidParam();
  const visit = useSelector(openedVisitSelector(visitUuid));
  const timezone = useSelector(timezoneSelector);

  if (!visit) {
    throw new Error('Visit not found in the state');
  }

  return {
    hostUuid: undefined,
    ...mapVisit(visit, timezone),
  };
};
