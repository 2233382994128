import { hasCreateGroupsPermissionsSelector } from 'app/store/user-permissions/selectors';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { SecondaryCtaProps } from './use-secondary-cta.type';
import { useLocation, useNavigate } from 'react-router';
import { useCallback, useMemo } from 'react';
import { GROUPS_CREATE_ROUTE } from 'app/shared/consts';
import { BACK_ROUTE_QUERY_PARAM } from 'app/components/visits/visit-modal/visit-modal.consts';

export const useCreateGroupCta = (): SecondaryCtaProps | undefined => {
  const { formatMessage } = useIntl();
  const hasCreateGroupsPermissions: boolean = useSelector(hasCreateGroupsPermissionsSelector);
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const handleNavigateTo = useCallback(() => {
    const backRoute = encodeURIComponent(`${pathname}${search}`);
    return navigate(`${pathname}/${GROUPS_CREATE_ROUTE}?${BACK_ROUTE_QUERY_PARAM}=${backRoute}`);
  }, [navigate, pathname, search]);

  return useMemo(() => {
    if (hasCreateGroupsPermissions) {
      return ({
        secondaryButtonText: formatMessage({ id: 'buttons.createNewGroup' }),
        onSecondaryButtonClick: handleNavigateTo,
        secondaryButtonProps: {
          variant: 'Outline',
        },
      });
    }
    return undefined;
  }, [hasCreateGroupsPermissions, formatMessage, handleNavigateTo]);
};
