import { ActionType, createReducer } from 'typesafe-actions';
import { VisitorState, VisitorStateByUuid } from './types';
import { fetchVisitor, updateVisitorName } from './actions';

export const initialState: VisitorStateByUuid = {};
export const initialVisitorState: VisitorState = {
  visitor: null,
  loading: false,
  error: null,
  updateVisitorNameRequest: {
    loading: false,
    error: null,
  },
};

const handleFetchVisitorRequest = (
  state: VisitorStateByUuid,
  action: ActionType<typeof fetchVisitor.request>,
): VisitorStateByUuid => ({
  ...state,
  [action.payload.uuid]: {
    ...initialVisitorState,
    loading: true,
  },
});

const handleFetchVisitorSuccess = (
  state: VisitorStateByUuid,
  action: ActionType<typeof fetchVisitor.success>,
): VisitorStateByUuid => ({
  ...state,
  [action.payload.params.uuid]: {
    ...state[action.payload.params.uuid],
    loading: false,
    visitor: action.payload.response,
    error: null,
  },
});

const handleFetchVisitorFailure = (
  state: VisitorStateByUuid,
  action: ActionType<typeof fetchVisitor.failure>,
): VisitorStateByUuid => ({
  ...state,
  [action.payload.params.uuid]: {
    ...state[action.payload.params.uuid],
    loading: false,
    error: action.payload.error,
  },
});

const handleUpdateVisitorNameRequest = (
  state: VisitorStateByUuid,
  action: ActionType<typeof updateVisitorName.request>,
): VisitorStateByUuid => {
  const currentVisitorState = state[action.payload.uuid];

  if (!currentVisitorState || !currentVisitorState.visitor) {
    return state;
  }

  return {
    ...state,
    [action.payload.uuid]: {
      ...currentVisitorState,
      visitor: {
        ...currentVisitorState.visitor,
        first_name: action.payload.firstName,
        last_name: action.payload.lastName,
      },
      updateVisitorNameRequest: {
        loading: true,
        error: null,
        originalFirstName: currentVisitorState.visitor.first_name,
        originalLastName: currentVisitorState.visitor.last_name,
      },
    },
  };
};

const handleUpdateVisitorNameSuccess = (
  state: VisitorStateByUuid,
  action: ActionType<typeof updateVisitorName.success>,
): VisitorStateByUuid => ({
  ...state,
  [action.payload.params.uuid]: {
    ...state[action.payload.params.uuid],
    visitor: action.payload.response.data,
    updateVisitorNameRequest: {
      loading: false,
      error: null,
    },
  },
});

const handleUpdateVisitorNameFailure = (
  state: VisitorStateByUuid,
  action: ActionType<typeof updateVisitorName.failure>,
): VisitorStateByUuid => {
  const currentVisitorState = state[action.payload.params.uuid];

  if (!currentVisitorState || !currentVisitorState.visitor) {
    return state;
  }

  return {
    ...state,
    [action.payload.params.uuid]: {
      ...currentVisitorState,
      visitor: {
        ...currentVisitorState.visitor,
        first_name:
          currentVisitorState.updateVisitorNameRequest.originalFirstName || currentVisitorState.visitor.first_name,
        last_name:
          currentVisitorState.updateVisitorNameRequest.originalLastName || currentVisitorState.visitor.last_name,
      },
      updateVisitorNameRequest: {
        loading: false,
        error: action.payload.response,
      },
    },
  };
};

export const visitorReducer = createReducer(initialState)
  .handleAction(fetchVisitor.request, handleFetchVisitorRequest)
  .handleAction(fetchVisitor.success, handleFetchVisitorSuccess)
  .handleAction(fetchVisitor.failure, handleFetchVisitorFailure)

  .handleAction(updateVisitorName.request, handleUpdateVisitorNameRequest)
  .handleAction(updateVisitorName.success, handleUpdateVisitorNameSuccess)
  .handleAction(updateVisitorName.failure, handleUpdateVisitorNameFailure);
