import { createTheme } from '@material-ui/core/styles';
import { hqoTheme } from '@hqo/react-components-library/dist/hqo-theme-provider';

const muiTheme = createTheme({
  palette: {
    primary: {
      main: hqoTheme.colors.palette.systemBlue,
    },
  },
  overrides: {
    MuiRadio: {
      root: {
        '&.Mui-checked': {
          color: hqoTheme.colors.palette.systemBlue,
        },
      },
    },
  },
});

export default muiTheme;
