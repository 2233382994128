import { useFormikContext } from 'formik';
import { FormValues } from '../visitor-groups.interfaces';
import { FC } from 'react';
import { VisitorGroupsModalHeader } from './visitor-groups-modal-header';
import { VisitorGroupsModalFooter } from './visitor-groups-modal-footer';
import { Modal } from 'app/components/shared/modal/modal';
import { useResolvedPath } from 'react-router-dom';
import { VisitorGroupsModalDetails } from './visitor-groups-modal-details';

export interface VisitorGroupsModalProps {
  onClose: VoidFunction;
}

export const VisitorGroupsModalView: FC<VisitorGroupsModalProps> = ({
  onClose: handleClose,
}) => {
  const { submitForm, values } = useFormikContext<FormValues>();
  const resolvedPath = useResolvedPath('.');

  return (
    <Modal
      basePath={resolvedPath.pathname}
      dataTestId="visitor-group-modal"
    >
      <>
        <Modal.Header>
          <VisitorGroupsModalHeader
            name={values.groupName !== '' ? values.groupName : 'G'}
            skeleton={false}
          />
        </Modal.Header>
        <VisitorGroupsModalDetails />
        <Modal.Footer>
          <VisitorGroupsModalFooter
            handleClose={handleClose}
            submitForm={submitForm}
          />
        </Modal.Footer>
      </>
    </Modal>
  );
};
