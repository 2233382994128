import { useCallback, useRef } from 'react';
import { useOnClickOutside } from '@hqo/react-components-library/dist/hooks/use-on-click-outside';
import { useField } from 'formik';
import { VisibilityEnum } from 'app/components/visits/visit-form/visit-form.interfaces';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks';
import { useSelector } from 'react-redux';
import { hasWaiveContactInfoEnabledSelector } from 'app/store/building-settings/selectors';
import { hasManageWaiveContactInfoPermissionsSelector } from 'app/store/user-permissions/selectors';

interface Props {
  fieldNamePrefix: string;
}

export interface UseMoreOptionsResponse {
  canEditWaiveContactInfo: boolean;
  onVisibilityChange: VoidFunction;
  onWaiveChange: VoidFunction;
  ref: React.RefObject<HTMLDivElement>;
  toggleVisibility: VoidFunction;
  visible: boolean;
  visibilityValue: string;
  waiveValue: boolean;
}

export const useMoreOptions: ({ fieldNamePrefix }: Props) => UseMoreOptionsResponse = ({ fieldNamePrefix }: Props): UseMoreOptionsResponse => {
  const { value: visible, setFalse, toggle } = useBooleanState(false);
  const hasWaiveContactInfoEnabled: boolean = useSelector(hasWaiveContactInfoEnabledSelector);
  const hasManageWaiveContactInfoPermissions: boolean = useSelector(hasManageWaiveContactInfoPermissionsSelector);
  const canEditWaiveContactInfo: boolean = hasWaiveContactInfoEnabled && hasManageWaiveContactInfoPermissions;

  const ref = useRef<HTMLDivElement>(null);

  const toggleVisibility: VoidFunction = useCallback((): void => {
    toggle();
  }, []);

  const [{ value: visibilityValue }, , { setValue: setVisibilityValue }] = useField(`${fieldNamePrefix}.visibility`);
  const [{ value: waiveValue }, , { setValue: setWaiveValue }] = useField(`${fieldNamePrefix}.contact_info_waived`);
  const [, , { setValue: setTypeIdValue }] = useField('visitTypeId');

  const onVisibilityChange = useCallback((): void => {
    setVisibilityValue(visibilityValue === VisibilityEnum.HOST_AND_CREATOR ? VisibilityEnum.PUBLIC : VisibilityEnum.HOST_AND_CREATOR);
  }, [setVisibilityValue, visibilityValue]);

  const onWaiveChange = useCallback((): void => {
    if (!waiveValue) {
      setTypeIdValue(1);
    }
    setWaiveValue(!waiveValue);
  }, [setTypeIdValue, setWaiveValue, waiveValue]);

  useOnClickOutside(() => setFalse(), [ref]);

  return { canEditWaiveContactInfo, onVisibilityChange, onWaiveChange, ref, toggleVisibility, visible, visibilityValue, waiveValue };
};
