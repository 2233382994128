import styled from 'styled-components';

export const Wrapper = styled.div<{ wrapperSize: number }>`
  width: ${({ wrapperSize }) => `${wrapperSize}px`};
  height: ${({ wrapperSize }) => `${wrapperSize}px`};
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.palette.systemGreyDark};
  padding: 8px;
  flex-shrink: 0;
  flex-grow: 0;
`;

export const AvatarImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Fallback = styled.span<{ fontSize: number }>`
  font-family: ${({ theme }) => theme.fonts.join()};
  font-size: ${({ fontSize }) => `${fontSize}px`};
  font-weight: 500;
  color: ${({ theme }) => theme.colors.palette.sharedWhite};
`;
