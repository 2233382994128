import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import {
  DAY_PASSES_ROUTE,
  REQUESTED_DAY_PASSES_ROUTE,
  VISITORS_PATH,
  WATCHLIST_ROUTE,
  VISITS_PATH,
  GROUPS_ROUTE,
} from 'app/shared/consts';
import { showAdminNav51Selector, showVisitorsTabSelector } from 'app/store/config/selectors';
import {
  canSeeWatchlistTabSelector,
  hasManageDaypassPermissionsSelector,
  hasRequestDaypassPermissionsSelector,
  hasViewGroupsPermissionsSelector,
} from 'app/store/user-permissions/selectors';
import { SecondaryCtaType } from 'app/components/secondary-cta';
import { TabConfig } from '../common-tabs/types';
import { Pill } from '@hqo/react-components-library/dist/atoms/pill';
import styled from 'styled-components';
import { pendingWatchlistMatchesCountSelector } from 'app/store/watchlist/selectors';

const StyledPill = styled(Pill)`
  margin-left: 8px;
`;

const visitsTab = (label: string): TabConfig => ({
  label,
  route: VISITS_PATH,
  dataCy: 'visits-tab',
  secondaryCtaType: SecondaryCtaType.EXPORT_AUDIT_REPORT,
});

const visitorsTab = (label: string): TabConfig => ({
  label,
  route: VISITORS_PATH,
  dataCy: 'visitors-tab',
});

const dayPassTab = (label: string): TabConfig => ({
  label,
  route: DAY_PASSES_ROUTE,
  dataCy: 'day-pass-tab',
});

const requestedDayPassTab = (label: string): TabConfig => ({
  label,
  route: REQUESTED_DAY_PASSES_ROUTE,
  dataCy: 'requested-day-pass-tab',
  secondaryCtaType: SecondaryCtaType.MANAGE_DAY_PASS_USERS,
});

const watchlistTab = (label: string, count: number): TabConfig => ({
  label,
  route: WATCHLIST_ROUTE,
  dataCy: 'watchlist-tab',
  secondaryCtaType: SecondaryCtaType.MANAGE_WATCHLIST_USERS,
  ...(count && { supplement: <StyledPill variant="badge">{count}</StyledPill> }),
});

const visitorGroupsTab = (label: string): TabConfig => ({
  label,
  route: GROUPS_ROUTE,
  dataCy: 'visitor-groups-tab',
  secondaryCtaType: SecondaryCtaType.CREATE_VISITOR_GROUP,
});

export const useRootTabConfigs = (): TabConfig[] => {
  const showAdminNav51 = useSelector(showAdminNav51Selector);
  const showVisitorsTab = useSelector(showVisitorsTabSelector);
  const showTenantEmployeesTab = useSelector(hasRequestDaypassPermissionsSelector);
  const showRequestsTab = useSelector(hasManageDaypassPermissionsSelector);
  const showWatchlistTab = useSelector(canSeeWatchlistTabSelector);
  const pendingWatchlistMatchesCount = useSelector(pendingWatchlistMatchesCountSelector);
  const showVisitorGroupsTab = useSelector(hasViewGroupsPermissionsSelector);
  const { formatMessage } = useIntl();

  return useMemo(
    () =>
      [
        visitsTab(formatMessage({ id: `tabs.visits${showAdminNav51 ? '.adminNav51' : ''}.title` })),
        showVisitorsTab && visitorsTab(formatMessage({ id: 'tabs.visitors.title' })),
        showTenantEmployeesTab && dayPassTab(formatMessage({ id: 'tabs.dayPass.title' })),
        showRequestsTab && requestedDayPassTab(formatMessage({ id: 'tabs.requestedDayPasses.title' })),
        showWatchlistTab && watchlistTab(formatMessage({ id: 'tabs.watclist.title' }), pendingWatchlistMatchesCount),
        showVisitorGroupsTab && visitorGroupsTab(formatMessage({ id: 'tabs.visitorGroups.title' })),
      ].filter((tab): tab is TabConfig => !!tab),
    [
      formatMessage,
      showVisitorsTab,
      showAdminNav51,
      showTenantEmployeesTab,
      showWatchlistTab,
      showRequestsTab,
      pendingWatchlistMatchesCount,
    ],
  );
};
