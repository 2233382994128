import { RootState } from 'app/store/reducer';
import { ApiClient } from './api-client';

export const apiClient = (store: Pick<RootState, 'config' | 'building' | 'buildingSettings'>): ApiClient => {
  const { config, building, buildingSettings } = store;
  const buildingUuid = building?.uuid;
  const appInstanceUuid = buildingSettings?.[buildingUuid]?.settings?.appInstanceUuid;

  return new ApiClient(config.apiUrl, config.getAuthToken, buildingUuid, appInstanceUuid);
};
