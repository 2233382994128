import { Epic } from 'redux-observable';
import { toast } from 'react-toastify';
import { isActionOf } from 'typesafe-actions';
import { catchError, concat, EMPTY, filter, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import { RootAction } from 'app/store/actions';
import { RootDependencies } from 'app/store/dependencies';
import { RootState } from 'app/store/reducer';
import { addVisitor, createVisitorGroup, deleteVisitor, fetchVisitorGroup, fetchVisitorGroups, updateVisitorGroupName } from './actions';
import { rootPathSelector } from '../config/selectors';
import { GROUPS_ROUTE } from 'app/shared/consts';

export const fetchVisitorGroupsEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchVisitorGroups.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      apiClient(state)
        .getVisitorGroups(action.payload)
        .pipe(
          map((response) => fetchVisitorGroups.success({ params: action.payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.visitorGroups.fetch.error' }));

            return of(fetchVisitorGroups.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );

export const fetchVisitorGroupEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchVisitorGroup.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      apiClient(state)
        .getVisitorGroup(action.payload)
        .pipe(
          map((response) => fetchVisitorGroup.success({ params: action.payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.visitorGroup.fetch.error' }));

            return of(fetchVisitorGroup.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );

export const createVisitorGroupEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(createVisitorGroup.request)),
    withLatestFrom(state$),
    mergeMap(([action, state]) => concat(
      apiClient(state)
        .createVisitorGroup(action.payload)
        .pipe(
          mergeMap(({ response }) => of(
            fetchVisitorGroups.request({ buildingUuid: action.payload.buildingUuid, shouldReset: true }),
            createVisitorGroup.success({ params: action.payload, response }),
          )),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.visitorGroup.create.error' }));

            return of(createVisitorGroup.failure({ params: action.payload, error }));
          }),
        ),
    )),
  );

export const createVisitorGroupSuccessEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { navigate },
) =>
  action$.pipe(
    filter(isActionOf(createVisitorGroup.success)),
    withLatestFrom(state$),
    mergeMap(([, state]) => {
      const rootPath = rootPathSelector(state);

      navigate(
        `${rootPath}/${GROUPS_ROUTE}`,
      );

      return EMPTY;
    }),
  );

export const updateVisitorGroupNameEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(updateVisitorGroupName.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      apiClient(state)
        .updateVisitorGroupName(action.payload)
        .pipe(
          map(({ response }) => updateVisitorGroupName.success({ params: action.payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.visitorGroup.updateName.error' }));

            return of(updateVisitorGroupName.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );

export const addVisitorEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(addVisitor.request)),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      apiClient(state)
        .addVisitor(action.payload)
        .pipe(
          mergeMap(({ response }) => of(
            addVisitor.success({ params: action.payload, response }),
          )),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.visitorGroup.addVisitor.error' }));

            return of(addVisitor.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );

export const deleteVisitorEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(deleteVisitor.request)),
    withLatestFrom(state$),
    mergeMap(([action, state]) =>
      apiClient(state)
        .deleteVisitor(action.payload)
        .pipe(
          mergeMap(() => of(
            fetchVisitorGroup.request({ buildingUuid: action.payload.buildingUuid, visitorGroupUuid: action.payload.visitorGroupUuid }),
            deleteVisitor.success({ params: action.payload }),
          )),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.visitorGroup.deleteVisitor.error' }));

            return of(deleteVisitor.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );
