import { PaginationPropsV2 } from '@hqo/react-components-library/dist/molecules/pagination-v2';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { MessageIds } from '../../../i18n';

export interface PaginationOptions {
  page: number;
  onPageChange: (page: number) => void;
  limit?: number;
  offset: number;
  total: number;
}

export const usePaginationOptions = (
  tableTranslationsKeyPrefix: string,
  options: PaginationOptions,
): PaginationPropsV2 => {
  const { formatMessage } = useIntl();
  const { page, onPageChange, limit, offset, total } = options;

  return useMemo(
    () => ({
      page,
      setPage: onPageChange,
      limit,
      offset,
      total,
      labels: {
        of: formatMessage({ id: `${tableTranslationsKeyPrefix}.pagination.of` as MessageIds }),
      },
    }),
    [formatMessage, limit, offset, onPageChange, page, total, tableTranslationsKeyPrefix],
  );
};
