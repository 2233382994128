import { createSelector } from 'reselect';
import { RootState } from 'app/store/reducer';
import { buildingUuidSelector } from 'app/store/building/selectors';
import { initialSettingsState } from 'app/store/building-settings/reducer';

export const buildingSettingsByBuildingState = (state: RootState) => state.buildingSettings;

export const buildingSettingsSelector = createSelector(
  [buildingUuidSelector, buildingSettingsByBuildingState],
  (buildingUuid, settingsByBuilding) => settingsByBuilding?.[buildingUuid] ?? initialSettingsState,
);

export const hasDayPassEnabledSelector = createSelector(
  [buildingSettingsSelector],
  (buildingSettings) => buildingSettings?.settings?.dayPass ?? false,
);

export const hasWatchlistEnabledSelector = createSelector(
  [buildingSettingsSelector],
  (buildingSettings) => buildingSettings?.settings?.watchlist ?? false,
);

export const hasVisitorPassesEnabledSelector = createSelector(
  [buildingSettingsSelector],
  (buildingSettings) => buildingSettings?.settings?.visitorPasses ?? false,
);

export const hasVisitorGroupsEnabledSelector = createSelector(
  [buildingSettingsSelector],
  (buildingSettings) => buildingSettings?.settings?.visitorGroups ?? false,
);

export const hasWaiveContactInfoEnabledSelector = createSelector(
  [buildingSettingsSelector],
  (buildingSettings) => buildingSettings?.settings?.allowToWaiveContactInfo ?? false,
);

export const isAppConfiguredSelector = createSelector(
  [buildingSettingsSelector],
  (buildingSettings) => buildingSettings.isConfigured,
);
