import { Epic } from 'redux-observable';
import { toast } from 'react-toastify';
import { isActionOf } from 'typesafe-actions';
import { catchError, filter, map, of, switchMap, withLatestFrom } from 'rxjs';
import { RootAction } from 'app/store/actions';
import { RootDependencies } from 'app/store/dependencies';
import { RootState } from 'app/store/reducer';
import { fetchVisitor, updateVisitorName } from './actions';

export const fetchVisitorEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(fetchVisitor.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      apiClient(state)
        .fetchVisitor(action.payload)
        .pipe(
          map((response) => fetchVisitor.success({ params: action.payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.visitorDetails.fetch.error' }));

            return of(fetchVisitor.failure({ params: action.payload, error }));
          }),
        ),
    ),
  );

export const updateVisitorNameEpic: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient, intl },
) =>
  action$.pipe(
    filter(isActionOf(updateVisitorName.request)),
    withLatestFrom(state$),
    switchMap(([action, state]) =>
      apiClient(state)
        .updateVisitorName(action.payload)
        .pipe(
          map(({ response }) => updateVisitorName.success({ params: action.payload, response })),
          catchError((error: Error) => {
            toast.error(intl.formatMessage({ id: 'notifications.visitors.updateName.error' }));
            return of(updateVisitorName.failure({ params: action.payload, response: error }));
          }),
        ),
    ),
  );
