import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { stringify } from 'qs';
import { enableVisitsRegistrationSelector } from 'app/store/config/selectors';
import { CREATE_VISIT_PATH } from 'app/shared/consts';
import { DEFAULT_START_DATE_QUERY_PARAM } from 'app/components/visits/visit-form/hooks/creation/use-initial-date-time-values.hook';
import { BACK_ROUTE_QUERY_PARAM } from 'app/components/visits/visit-modal/visit-modal.consts';
import { VISITS_DATE_FROM_FILTER_QUERY_PARAM } from 'app/components/visits/visits-date-filter/use-visits-date-filter.hook';
import { Permission } from 'app/store/user-permissions/types';
import { useUserPermissionCheck } from 'app/store/user-permissions/hooks';
import { PageHeaderProps } from '@hqo/react-components-library/dist/organisms/hqo-page-header';

interface PossibleEvent {
  stopPropagation?: () => void
}

export const useCreateVisitButton = (): Pick<
  PageHeaderProps,
  'ctaButtonText' | 'onCtaButtonClick' | 'ctaButtonProps'
> => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const backRoute = `${pathname}${search}`;
  const [searchParams] = useSearchParams();
  const visitCreationEnabled = useSelector(enableVisitsRegistrationSelector);
  const canCreateVisit = useUserPermissionCheck(Permission.BULK_CREATE);
  const defaultVisitDate = searchParams.get(VISITS_DATE_FROM_FILTER_QUERY_PARAM);

  const onCreateVisitClick = useCallback((event?: PossibleEvent) => {
    event?.stopPropagation?.();
    navigate(
      `${CREATE_VISIT_PATH}${stringify(
        {
          [BACK_ROUTE_QUERY_PARAM]: backRoute,
          [DEFAULT_START_DATE_QUERY_PARAM]: defaultVisitDate,
        },
        { skipNulls: true, addQueryPrefix: true },
      )}`,
    );
  }, [navigate, backRoute, defaultVisitDate]);

  return useMemo(
    () => ({
      ctaButtonText: visitCreationEnabled && canCreateVisit ? formatMessage({ id: 'buttons.createVisit' }) : undefined,
      onCtaButtonClick: onCreateVisitClick as VoidFunction,
      ctaButtonProps: { dataCy: 'create-visit-button' },
    }),
    [onCreateVisitClick, visitCreationEnabled, canCreateVisit, formatMessage],
  );
};
