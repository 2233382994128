import { HqOTable } from '@hqo/react-components-library/dist/organisms/hqo-table';

import styled from 'styled-components';

export const Table = styled(HqOTable)`
  &,
  & * {
    box-sizing: border-box;
  }

  .table-cell {
    flex-shrink: 0;
  }
`;
