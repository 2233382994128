import { IntlProvider } from 'app/components/intl-provider';
import { Building } from 'app/store/building/types';
import { User } from 'app/store/user/types';
import { FC, forwardRef } from 'react';
import { HqoThemeProvider } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { AppThemeProvider, defaultTheme } from '@hqo/react-components-library/dist/molecules/theme';
import { ReactAdapterProvider } from 'app/components/react-adapter-provider/react-adapter-provider';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'app/components/toast-provider';
import { ReduxProvider } from 'app/components/redux-provider';
import { FeatureFlags } from 'app/store/config/types';
import { AppRoot } from 'app/components/app-root';
import { createGlobalStyle } from 'styled-components';
import { ThemeProvider } from '@material-ui/core/styles';
import muiTheme from './theme/muiTheme';

export interface VisitorRegistrationAppProps {
  apiUrl: string;
  publicUrl?: string;
  getAuthToken: () => Promise<string>;
  locale: string;
  rootPath?: string;
  user: User;
  building: Building;
  featureFlags?: Record<FeatureFlags, boolean>;
}

export const VisitorRegistrationAppGlobalStyles = createGlobalStyle`
  .application-root-ref {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const VisitorRegistrationApp: FC<VisitorRegistrationAppProps> = ({
  apiUrl,
  getAuthToken,
  locale,
  rootPath,
  user,
  building,
  featureFlags,
  publicUrl = process.env.PUBLIC_URL,
}) => (
  <BrowserRouter>
    <HqoThemeProvider>
      <AppThemeProvider theme={defaultTheme}>
        <ThemeProvider theme={muiTheme}>
          <IntlProvider configLocale={locale}>
            <ReduxProvider
              apiUrl={apiUrl}
              publicUrl={publicUrl}
              getAuthToken={getAuthToken}
              locale={locale}
              rootPath={rootPath}
              user={user}
              building={building}
              featureFlags={featureFlags}
            >
              <VisitorRegistrationAppGlobalStyles />
              <ToastProvider />
              <AppRoot />
            </ReduxProvider>
          </IntlProvider>
        </ThemeProvider>
      </AppThemeProvider>
    </HqoThemeProvider>
  </BrowserRouter>
);

export const VisitorRegistrationAppAdapted = forwardRef<
  ReactAdapterProvider<VisitorRegistrationAppProps>,
  VisitorRegistrationAppProps
>((props, ref) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <ReactAdapterProvider<VisitorRegistrationAppProps> {...props} component={VisitorRegistrationApp} ref={ref} />
));

VisitorRegistrationAppAdapted.displayName = 'VisitorRegistrationAppAdapted';

export default VisitorRegistrationAppAdapted;
