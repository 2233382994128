import { useMemo } from 'react';
import type { FormValues } from '../../visitor-groups.interfaces';
import type { DeepPartial } from 'app/shared/types';
import { array, object, Schema, string, TestContext } from 'yup';
import { isPossiblePhoneNumber } from 'react-phone-number-input';
import { FeatureEnablementFlagEnum } from 'app/store/ui-metadata/types';
import type { UiMetadata } from 'app/store/ui-metadata/types';
import { useUiMetadata } from 'app/store/ui-metadata/hooks';

function testVisitorEmailOrPhoneIsRequired(emailOrPhoneCase?: boolean) {
  return (_: string | null | undefined, context: TestContext): boolean => {
    if (emailOrPhoneCase) {
      const { phone, email } = context.parent;
      return !!phone || !!email;
    }
    return true;
  };
}

function testVisitorEmailIsRequired(visitorEmail?: FeatureEnablementFlagEnum) {
  return (value: string | null | undefined): boolean => {
    if (visitorEmail === FeatureEnablementFlagEnum.ENABLED_REQUIRED) {
      return !!value;
    }
    return true;
  };
}

function testVisitorPhoneIsRequired(visitorPhone?: FeatureEnablementFlagEnum) {
  return (value: string | null | undefined): boolean => {
    if (visitorPhone === FeatureEnablementFlagEnum.ENABLED_REQUIRED) {
      return !!value;
    }
    return true;
  };
}

const getValidationSchema = (uiMetadata: UiMetadata | null): Schema<DeepPartial<FormValues>> => {
  const visitorPhone = uiMetadata?.ui_metadata.visitor_phone;
  const visitorEmail = uiMetadata?.ui_metadata.visitor_email;
  const emailOrPhoneCase = uiMetadata?.ui_metadata.email_or_phone_case;

  return object().shape({
    groupName: string().max(30, 'errors.groupNameMaxLength')
      .required('errors.groupNameIsRequired'),
    visitors: array()
      .of(
        object().shape({
          firstName: string().required('errors.pleaseEnterAValidFirstName'),
          lastName: string().required('errors.pleaseEnterAValidLastName'),
          email: string()
            .email('errors.pleaseEnterAValidEmail')
            .test('is-required', 'errors.emailOrPhoneRequired', testVisitorEmailOrPhoneIsRequired(emailOrPhoneCase))
            .test('is-required', 'errors.emailRequired', testVisitorEmailIsRequired(visitorEmail)),
          phone: string()
            .test(
              'is-valid-phone',
              'errors.pleaseEnterAValidPhoneNumber',
              (value?: string) => !value || isPossiblePhoneNumber(value),
            )
            .test('is-required', 'errors.emailOrPhoneRequired', testVisitorEmailOrPhoneIsRequired(emailOrPhoneCase))
            .test('is-required', 'errors.phoneRequired', testVisitorPhoneIsRequired(visitorPhone)),
        }),
      )
      .required(),
  });
};

export const useValidationSchema = () => {
  const metadata = useUiMetadata();

  return useMemo(
    () => getValidationSchema(metadata),
    [metadata],
  );
};
