import { FC, useCallback, useMemo } from 'react';
import { VisitorDetails } from '../../../../shared/visitor-details/visitor-details';
import { ButtonWrapper, Container, VisitorControlsWrapper } from './visitors.styles';
import { useFormikContext } from 'formik';
import { FormValues } from '../../visit-form.interfaces';
import { AddVisitorButton } from './add-visitor-button/add-visitor-button';
import { Header } from './header';
import { GroupVisit } from './group-visit';
import { useDeleteVisitor, useResetVisitor } from 'app/components/visits/visit-form/hooks';

interface VisitorsProps {
  canAddVisitor: boolean;
  canSaveAsGroup: boolean;
  canEditVisitorDetails: boolean;
  canChangeVisitVisibility?: boolean;
}

export const Visitors: FC<VisitorsProps> = ({ canAddVisitor, canEditVisitorDetails, canChangeVisitVisibility, canSaveAsGroup }) => {
  const {
    values: { visitors },
  } = useFormikContext<FormValues>();
  const showDeleteVisitorButton = useMemo(() => visitors.length > 1, [visitors]);
  const handleVisitorDelete = useDeleteVisitor();
  const handleResetVisitor = useResetVisitor();

  const onVisitorDelete = useCallback((index: number) => () => {
    handleVisitorDelete(index);
  }, [handleVisitorDelete]);

  return (
    <Container>
      <Header canAddVisitor={canAddVisitor} canSaveAsGroup={canSaveAsGroup} />
      {visitors.map((_, index) => (
        <VisitorDetails
          // only for adding/removing visitors, no reordering
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          isDeleteAvailable={showDeleteVisitorButton}
          canEditVisitorDetails={canEditVisitorDetails}
          canChangeVisitVisibility={canChangeVisitVisibility}
          fieldNamePrefix={`visitors[${index}]`}
          handleVisitorDelete={onVisitorDelete(index)}
          handleResetVisitor={handleResetVisitor(index)}
        />
      ))}
      <VisitorControlsWrapper>
        {canAddVisitor && (
          <ButtonWrapper>
            <AddVisitorButton />
          </ButtonWrapper>
        )}
        {canSaveAsGroup && <GroupVisit />}
      </VisitorControlsWrapper>
    </Container>
  );
};
