import { Route, Routes } from 'react-router-dom';

import { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  enableVisitsRegistrationSelector,
  rootPathSelector,
  showVisitorsTabSelector,
} from 'app/store/config/selectors';
import { DefaultVisitsRouteRedirect } from 'app/components/router/default-visits-route-redirect';
import { VisitsRootPage } from 'app/pages/root';
import {
  ADD_WATCHLIST_PERSON_ROUTE,
  CREATE_VISIT_PATH,
  CREDENTIAL_SCANNER_PATH,
  DAY_PASSES_ROUTE,
  DETAILS_PATH,
  EDIT_VISIT_PATH,
  GROUPS_CREATE_ROUTE,
  GROUPS_DETAILS_ROUTE,
  GROUPS_ROUTE,
  HOST_DETAILS_ROUTE,
  MANAGE_DAY_PASSES_ROUTE,
  MANAGE_WATCHLIST_ROUTE,
  PASS_PATH,
  PRINT_DAY_PASS_ROUTE,
  REQUESTED_DAY_PASSES_ROUTE,
  SETTINGS_PATH,
  SettingsType,
  VISIT_UUID_PARAMETER,
  VISITOR_DETAILS_ROUTE,
  VISITORS_PATH,
  VISITS_PATH,
  VisitsByDate,
  WATCHLIST_ROUTE,
} from 'app/shared/consts';
import { VisitsPage } from 'app/pages/visits';
import { CreateVisitPage } from 'app/pages/create-visit';
import { RouteGuard } from 'app/components/router/route-guard';
import { GeneralSettingsPage } from 'app/pages/settings/general-settings/general-settings';
import { FeatureSettingsPage } from 'app/pages/settings/feature-settings/feature-settings';
import { AccessGroupsSettingsPage } from 'app/pages/settings/access-groups-settings/access-groups-settings';
import { SettingsPage } from 'app/pages/settings';
import { UpdateVisitPage } from 'app/pages/update-visit';
import { VisitPassPage } from 'app/pages/visit-pass';
import { VisitorsPage } from 'app/pages/visitors';
import { CredentialScannerPage } from 'app/pages/credential-scanner';
import { Loader } from 'app/components/loader';
import { HostDetailsPage } from 'app/pages/host-details';
import { DetailsPage } from 'app/pages/details';
import { VisitorDetailsPage } from 'app/pages/visitor-details';
import { RegisteredVisitorsPage } from 'app/pages/registered-visitors';
import { DefaultVisitorDetailsRouteRedirect } from 'app/components/router/default-visitor-details-route-redirect';
import { VisitorVisitsPage } from 'app/pages/visitor-visits';
import { VisitsTableDateFilter } from 'app/components/details/visitor/table/visits-table.consts';
import { DayPassesPage } from 'app/pages/day-passes';
import { RequestedDayPassesPage } from 'app/pages/requested-day-passes';
import { WatchlistPage } from 'app/pages/watchlist';
import {
  hasManageVisitorEmailPermissionSelector,
  hasManageDaypassPermissionsSelector,
  hasRequestDaypassPermissionsSelector,
  hasManageDaypassUsersPermissionsSelector,
  hasManageGeneralSettingsPermissionSelector,
  hasSomeManageSettingsPermissions,
  hasManageAccessGroupsSettingsPermissionSelector,
  hasAnyManageFeaturePermissionsSelector,
  canSeeWatchlistTabSelector,
  hasViewGroupsPermissionsSelector,
} from 'app/store/user-permissions/selectors';
import { ManageDayPassesPage } from 'app/pages/manage-day-passes/manage-day-passes';
import { DayPassPage } from 'app/pages/day-pass';
import { EmailTemplateSettingsPage } from 'app/pages/settings/email-template-settings/email-template-settings';
import { useRouterBootstrap } from './use-router-bootstrap.hook';
import { DayPassSettingsPage } from 'app/pages/settings/day-pass-settings/day-pass-settings';
import { DefaultSettingsRouteRedirect } from './default-settings-route-redirect';
import { ManageWatchlistPage } from 'app/pages/manage-watchlist/manage-watchlist';
import { AddWatchlistPersonPage } from 'app/pages/add-watchlist-person/add-watchlist-person';
import { VisitorGroupsPage } from 'app/pages/visitor-groups/visitor-groups';
import { VisitorGroupDetailsPage } from 'app/pages/visitor-group-details/visitor-group-details';
import { VisitorGroupCreatePage } from 'app/pages/visitor-group-create/visitor-group-create';

export const AppRouter: FC = (): JSX.Element => {
  const rootPath = useSelector(rootPathSelector);

  const isBootstrapComplete = useRouterBootstrap();

  if (!isBootstrapComplete) {
    return <Loader testId="permissions-loader" />;
  }

  return (
    <Routes>
      <Route path={rootPath}>
        <Route element={<VisitsRootPage />}>
          <Route path={VISITS_PATH} element={<VisitsPage />}>
            <Route path={`:${VISIT_UUID_PARAMETER}/${PASS_PATH}`} element={<VisitPassPage />} />
            <Route path={CREDENTIAL_SCANNER_PATH} element={<CredentialScannerPage />} />
            <Route path={DETAILS_PATH} element={<DetailsPage />}>
              <Route path={HOST_DETAILS_ROUTE} element={<HostDetailsPage />}>
                <Route index element={<RegisteredVisitorsPage />} />
              </Route>
              <Route path={VISITOR_DETAILS_ROUTE} element={<VisitorDetailsPage />}>
                <Route
                  path={VisitsByDate.UPCOMING}
                  element={<VisitorVisitsPage dateFilterKey={VisitsTableDateFilter.UPCOMING} />}
                />
                <Route
                  path={VisitsByDate.PREVIOUS}
                  element={<VisitorVisitsPage dateFilterKey={VisitsTableDateFilter.PREVIOUS} />}
                />
                <Route index element={<DefaultVisitorDetailsRouteRedirect />} />
                <Route path="*" element={<DefaultVisitorDetailsRouteRedirect />} />
              </Route>
            </Route>
          </Route>
          <Route element={<RouteGuard selector={enableVisitsRegistrationSelector} />}>
            <Route path={CREATE_VISIT_PATH} element={<CreateVisitPage />} />
            <Route path={`${EDIT_VISIT_PATH}/:${VISIT_UUID_PARAMETER}`} element={<UpdateVisitPage />} />
          </Route>
          <Route element={<RouteGuard selector={showVisitorsTabSelector} />}>
            <Route path={VISITORS_PATH} element={<VisitorsPage />}>
              <Route path={DETAILS_PATH} element={<DetailsPage />}>
                <Route path={HOST_DETAILS_ROUTE} element={<HostDetailsPage />}>
                  <Route index element={<RegisteredVisitorsPage />} />
                </Route>
                <Route path={VISITOR_DETAILS_ROUTE} element={<VisitorDetailsPage />}>
                  <Route
                    path={VisitsByDate.UPCOMING}
                    element={<VisitorVisitsPage dateFilterKey={VisitsTableDateFilter.UPCOMING} />}
                  />
                  <Route
                    path={VisitsByDate.PREVIOUS}
                    element={<VisitorVisitsPage dateFilterKey={VisitsTableDateFilter.PREVIOUS} />}
                  />
                  <Route index element={<DefaultVisitorDetailsRouteRedirect />} />
                  <Route path="*" element={<DefaultVisitorDetailsRouteRedirect />} />
                </Route>
              </Route>
            </Route>
          </Route>
          <Route element={<RouteGuard selector={hasRequestDaypassPermissionsSelector} />}>
            <Route path={DAY_PASSES_ROUTE} element={<DayPassesPage />}>
              <Route path={PRINT_DAY_PASS_ROUTE} element={<DayPassPage />} />
            </Route>
          </Route>
          <Route element={<RouteGuard selector={hasManageDaypassPermissionsSelector} />}>
            <Route path={REQUESTED_DAY_PASSES_ROUTE} element={<RequestedDayPassesPage />}>
              <Route path={MANAGE_DAY_PASSES_ROUTE} element={<ManageDayPassesPage />} />
            </Route>
          </Route>
          <Route element={<RouteGuard selector={hasViewGroupsPermissionsSelector} />}>
            <Route path={GROUPS_ROUTE} element={<VisitorGroupsPage />}>
              <Route path={GROUPS_DETAILS_ROUTE} element={<VisitorGroupDetailsPage />} />
              <Route path={GROUPS_CREATE_ROUTE} element={<VisitorGroupCreatePage />} />
            </Route>
          </Route>
          <Route element={<RouteGuard selector={canSeeWatchlistTabSelector} />}>
            <Route path={WATCHLIST_ROUTE} element={<WatchlistPage />}>
              <Route path={MANAGE_WATCHLIST_ROUTE} element={<ManageWatchlistPage />} />
              <Route path={ADD_WATCHLIST_PERSON_ROUTE} element={<AddWatchlistPersonPage />} />
            </Route>
          </Route>
        </Route>

        <Route element={<RouteGuard selector={hasSomeManageSettingsPermissions} />}>
          <Route path={SETTINGS_PATH} element={<SettingsPage />}>
            <Route element={<RouteGuard selector={hasManageGeneralSettingsPermissionSelector} />}>
              <Route path={SettingsType.GENERAL} element={<GeneralSettingsPage />} />
            </Route>
            <Route element={<RouteGuard selector={hasAnyManageFeaturePermissionsSelector} />}>
              <Route path={SettingsType.FEATURE} element={<FeatureSettingsPage />} />
            </Route>
            <Route element={<RouteGuard selector={hasManageVisitorEmailPermissionSelector} />}>
              <Route path={SettingsType.EMAIL} element={<EmailTemplateSettingsPage />} />
            </Route>
            <Route element={<RouteGuard selector={hasManageAccessGroupsSettingsPermissionSelector} />}>
              <Route path={SettingsType.ACCESS_GROUPS} element={<AccessGroupsSettingsPage />} />
            </Route>
            <Route element={<RouteGuard selector={hasManageDaypassUsersPermissionsSelector} />}>
              <Route path={SettingsType.DAY_PASS} element={<DayPassSettingsPage />}>
                <Route path={MANAGE_DAY_PASSES_ROUTE} element={<ManageDayPassesPage />} />
              </Route>
            </Route>
            <Route index element={<DefaultSettingsRouteRedirect />} />
            <Route path="*" element={<DefaultSettingsRouteRedirect />} />
          </Route>
        </Route>

        <Route index element={<DefaultVisitsRouteRedirect />} />
        <Route path="*" element={<DefaultVisitsRouteRedirect />} />
      </Route>
    </Routes>
  );
};
