import {
  AddVisitorErrorDto,
  AddVisitorPayloadDto,
  AddVisitorSuccessDto,
  CreateVisitorGroupErrorDto,
  CreateVisitorGroupPayloadDto,
  CreateVisitorGroupSuccessDto,
  DeleteVisitorErrorDto,
  DeleteVisitorPayloadDto,
  DeleteVisitorSuccessDto,
  FetchVisitorGroupFailureDto,
  FetchVisitorGroupPayloadDto,
  FetchVisitorGroupsFailureDto,
  FetchVisitorGroupsPayloadDto,
  FetchVisitorGroupsSuccessDto,
  FetchVisitorGroupSuccessDto,
  UpdateVisitorGroupNameFailureDto,
  UpdateVisitorGroupNamePayloadDto,
  UpdateVisitorGroupNameSuccessDto,
} from 'app/store/visitor-groups/types';
import { createAsyncAction } from 'typesafe-actions';

export const fetchVisitorGroups = createAsyncAction(
  'VISITOR_GROUPS/FETCH_REQUEST',
  'VISITOR_GROUPS/FETCH_SUCCESS',
  'VISITOR_GROUPS/FETCH_FAILURE',
)<FetchVisitorGroupsPayloadDto, FetchVisitorGroupsSuccessDto, FetchVisitorGroupsFailureDto>();

export const fetchVisitorGroup = createAsyncAction(
  'VISITOR_GROUP/FETCH_REQUEST',
  'VISITOR_GROUP/FETCH_SUCCESS',
  'VISITOR_GROUP/FETCH_FAILURE',
)<FetchVisitorGroupPayloadDto, FetchVisitorGroupSuccessDto, FetchVisitorGroupFailureDto>();

export const updateVisitorGroupName = createAsyncAction(
  'VISITOR_GROUP/UPDATE_NAME_REQUEST',
  'VISITOR_GROUP/UPDATE_NAME_SUCCESS',
  'VISITOR_GROUP/UPDATE_NAME_FAILURE',
)<UpdateVisitorGroupNamePayloadDto, UpdateVisitorGroupNameSuccessDto, UpdateVisitorGroupNameFailureDto>();

export const createVisitorGroup = createAsyncAction(
  'VISITOR_GROUP/CREATE_REQUEST',
  'VISITOR_GROUP/CREATE_SUCCESS',
  'VISITOR_GROUP/CREATE_FAILURE',
)<CreateVisitorGroupPayloadDto, CreateVisitorGroupSuccessDto, CreateVisitorGroupErrorDto>();

export const addVisitor = createAsyncAction(
  'VISITOR_GROUP/ADD_VISITOR_REQUEST',
  'VISITOR_GROUP/ADD_VISITOR_SUCCESS',
  'VISITOR_GROUP/ADD_VISITOR_FAILURE',
)<AddVisitorPayloadDto, AddVisitorSuccessDto, AddVisitorErrorDto>();

export const deleteVisitor = createAsyncAction(
  'VISITOR_GROUP/DELETE_VISITOR_REQUEST',
  'VISITOR_GROUP/DELETE_VISITOR_SUCCESS',
  'VISITOR_GROUP/DELETE_VISITOR_FAILURE',
)<DeleteVisitorPayloadDto, DeleteVisitorSuccessDto, DeleteVisitorErrorDto>();
