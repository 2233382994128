import styled from 'styled-components';

const MODAL_HEADER_HEIGHT = 100;
const MODAL_FOOTER_HEIGHT = 73;

export const VisitModalTitle = styled.h2`
  font-size: 24px;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.join()};
  margin: 0;
  color: ${({ theme }) => theme.colors.palette.systemBlack};
`;

export const VisitModalSubtitle = styled.p`
  font-size: 14px;
  font-family: ${({ theme }) => theme.fonts.join()};
  margin: 0;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.palette.systemGreyDark};
`;

export const VisitModalBody = styled.div`
  padding: 24px;
  height: calc(100% - ${MODAL_HEADER_HEIGHT + MODAL_FOOTER_HEIGHT}px);
  overflow-y: scroll;
`;

export const VisitModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;
