import { useFieldTranslations } from 'app/shared/hooks/use-field-translations';
import { useField } from 'formik';
import { useCallback, useMemo } from 'react';

export const useSearchField = (
  fieldNamePrefix: string,
  searchVisitor: (searchTerm: string) => void,
) => {
  const fieldName = useMemo(() => `${fieldNamePrefix}.email`, [fieldNamePrefix]);
  const [field, { error, touched }] = useField(fieldName);
  const { label, placeholder } = useFieldTranslations('visitor.email');

  const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    searchVisitor(event.target.value);
    field.onChange(event);
  }, [searchVisitor, field.onChange]);

  return { fieldName, field, label, placeholder, touched, error, handleChange };
};
