import { FC, useCallback, useState } from 'react';
import { ParseResult } from 'papaparse';
import { useFormikContext } from 'formik';
import { FormValues, VisibilityEnum, Visitor } from 'app/components/visits/visit-form/visit-form.interfaces';
import { useFileInputControl, useFileUpload } from '../hooks';
import { Container } from './upload-visitors.styles';
import { FileUploadInput } from './file-upload-input';
import { UploadButton } from './upload-button';

const ensureDefaultVisibility = (visitor: Visitor) => ({ visibility: VisibilityEnum.PUBLIC, ...visitor });

export const UploadVisitors: FC = () => {
  const [inputKey, setInputKey] = useState(Date.now());
  const { setFieldValue } = useFormikContext<FormValues>();
  const [handleOnButtonClick, inputRef] = useFileInputControl();

  const handleFileUploadComplete = useCallback(
    async (parsedVisitors: ParseResult<Visitor>) => {
      await setFieldValue('visitors', parsedVisitors.data?.map(ensureDefaultVisibility), true);
      setInputKey(Date.now());
    },
    [setFieldValue],
  );

  const handleFileUpload = useFileUpload(handleFileUploadComplete);

  return (
    <Container>
      <FileUploadInput key={inputKey} onUpload={handleFileUpload} ref={inputRef} />
      <UploadButton onClick={handleOnButtonClick} />
    </Container>
  );
};
