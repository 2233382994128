import { useCallback } from 'react';
import { DropdownOption } from '@hqo/react-components-library/dist/molecules/search-input-v2/components/dropdown-item';
import { Visitor } from 'app/store/visitor/types';
import { useField } from 'formik';
import { VisibilityEnum, Visitor as VisitorFormValues } from 'app/components/visits/visit-form/visit-form.interfaces';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks/use-boolean-state';

const emptyVisitorData: VisitorFormValues = {
  uuid: undefined,
  type: undefined,
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  visibility: VisibilityEnum.PUBLIC,
  contact_info_waived: false,
};

const mapVisitorToFormValues = (visitor: Visitor): VisitorFormValues => ({
  firstName: visitor.first_name || emptyVisitorData.firstName,
  lastName: visitor.last_name || emptyVisitorData.lastName,
  email: visitor.email || emptyVisitorData.email,
  phone: visitor.phone || emptyVisitorData.phone,
  uuid: visitor.uuid || emptyVisitorData.uuid,
  type: visitor.type || emptyVisitorData.type,
  visibility: visitor.visibility || emptyVisitorData.visibility,
  contact_info_waived: visitor.contact_info_waived || emptyVisitorData.contact_info_waived,
});

export const useSearchDropdown = (
  fieldNamePrefix: string,
  visitorOptions: Visitor[],
  clearSearch: VoidFunction,
) => {
  const { value: isDropdownVisible, setTrue: openDropdown, setFalse: closeDropdown } = useBooleanState(false);
  const [, , { setValue }] = useField(fieldNamePrefix);

  const handleClearButtonClick = useCallback(() => {
    setValue(emptyVisitorData);
    clearSearch();
  }, [setValue, clearSearch]);

  const handleSelectOption = useCallback(
    ({ value: selectedValue }: DropdownOption) => {
      const selectedVisitor = visitorOptions.find((visitor) => visitor.uuid === selectedValue);

      if (selectedVisitor) {
        setValue(mapVisitorToFormValues(selectedVisitor));
        closeDropdown();
      }
    },
    [visitorOptions, setValue, closeDropdown],
  );

  return {
    isDropdownVisible,
    handleClearButtonClick,
    handleSelectOption,
    handleClickOutsideDropdown: closeDropdown,
    handleClick: openDropdown,
  };
};
