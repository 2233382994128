import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { useFormikContext } from 'formik';
import {
  VisitModalBody,
  VisitModalFooter,
  VisitModalSubtitle,
  VisitModalTitle,
} from './visit-modal.styles';
import { FormFeatureFlags, FormValues } from 'app/components/visits/visit-form/visit-form.interfaces';
import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { VisitFormContent } from '../visit-form/visit-form-content';
import { MessageIds } from '../../../../i18n';
import { Modal } from 'app/components/shared/modal';
import { useResolvedPath } from 'react-router-dom';

export interface VisitModalProps extends FormFeatureFlags {
  onClose?: VoidFunction;
  translationPrefix: string;
  shouldReplaceInitialDate?: boolean;
  canChangeVisitVisibility?: boolean;
}

export const VisitModalView: FC<VisitModalProps> = ({
  onClose: handleClose,
  translationPrefix,
  canAddVisitor,
  canEditVisitTypes,
  canEditVisitorDetails,
  canSelectMultipleDates,
  canEditHost,
  shouldReplaceInitialDate,
  canChangeVisitVisibility,
  canSaveAsGroup,
}) => {
  const { formatMessage } = useIntl();
  const { submitForm } = useFormikContext<FormValues>();
  const resolvedPath = useResolvedPath('..');

  return (
    <Modal dataTestId="visit-modal" onClose={handleClose} basePath={resolvedPath.pathname}>
      <>
        <Modal.Header>
          <div>
            <VisitModalTitle>
              <FormattedMessage id={`${translationPrefix}.title` as MessageIds} />
            </VisitModalTitle>
            <VisitModalSubtitle>
              <FormattedMessage id={`${translationPrefix}.subTitle` as MessageIds} />
            </VisitModalSubtitle>
          </div>
        </Modal.Header>
        <VisitModalBody>
          <VisitFormContent
            canSaveAsGroup={canSaveAsGroup}
            canAddVisitor={canAddVisitor}
            canEditVisitTypes={canEditVisitTypes}
            canEditVisitorDetails={canEditVisitorDetails}
            canSelectMultipleDates={canSelectMultipleDates}
            canEditHost={canEditHost}
            shouldReplaceInitialDate={shouldReplaceInitialDate}
            canChangeVisitVisibility={canChangeVisitVisibility}
          />
        </VisitModalBody>
        <Modal.Footer>
          <VisitModalFooter>
            <Button
              text={formatMessage({ id: 'buttons.cancel' })}
              variant="Plain"
              onClick={handleClose}
              data-testid="cancel-button"
            />
            <Button
              text={formatMessage({ id: `${translationPrefix}.button.submit` as MessageIds })}
              variant="Primary"
              onClick={submitForm}
              data-testid="submit-visit-button"
            />
          </VisitModalFooter>
        </Modal.Footer>
      </>
    </Modal>
  );
};
