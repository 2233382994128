import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { toDate } from 'date-fns-tz';
import { FormValues, VisibilityEnum, VisitDateTime, Visitor } from 'app/components/visits/visit-form/visit-form.interfaces';
import { VISITORS_INITIAL_VALUES_QUERY_STRING_KEY } from 'app/components/visits/visit-form/hooks/creation/get-visitors-initial-value-query-string.util';
import { useQueryParam } from 'app/shared/hooks/use-query-param';
import { CREATE_ROUTE_QUERY_PARAM } from 'app/components/visits/visit-modal/visit-modal.consts';
import { VisitorGroup } from 'app/store/visitor-groups/types';
import { useSelector } from 'react-redux';
import { visitorGroupSelector } from 'app/store/visitor-groups/selectors';

export const initialVisitorValues = {
  phone: '',
  firstName: '',
  lastName: '',
  email: '',
  visibility: VisibilityEnum.PUBLIC,
  contact_info_waived: false,
};

type PartialInitialValues = Partial<FormValues> & Pick<FormValues, 'visitors'>;

const extractVisitors = (searchParams: qs.ParsedQs, visitorGroup: VisitorGroup | null): Visitor[] => {
  let visitors = Array.isArray(searchParams[VISITORS_INITIAL_VALUES_QUERY_STRING_KEY])
    ? searchParams[VISITORS_INITIAL_VALUES_QUERY_STRING_KEY]
    : [] as Visitor[];

  if (visitorGroup) {
    visitors = visitorGroup?.visitors?.map((visitor: Visitor) => ({
      ...initialVisitorValues,
      ...visitor,
      phone: visitor.phone || '',
    })) as unknown as string[];
  }

  if (!visitors.length) {
    visitors = [initialVisitorValues];
  }

  return visitors as unknown as Visitor[];
};

const extractVisitTypeId = (searchParams: qs.ParsedQs): number | undefined => {
  if (typeof searchParams.visitTypeId === 'string' && parseInt(searchParams.visitTypeId, 10) > 0) {
    return parseInt(searchParams.visitTypeId, 10);
  }

  return undefined;
};

const isVisitDateTime = (obj: unknown): obj is VisitDateTime =>
  !!obj && typeof obj === 'object' && 'startDate' in obj && !!obj.startDate && 'startTime' in obj && 'endTime' in obj;

const extractVisitDateTimes = (searchParams: qs.ParsedQs): VisitDateTime[] | undefined => {
  const { visitDateTimes } = searchParams;
  let validDateTimes: VisitDateTime[] = [];

  if (Array.isArray(visitDateTimes) && visitDateTimes.length) {
    validDateTimes = (visitDateTimes.filter(isVisitDateTime) as unknown as VisitDateTime[])
      .map((dateTime: VisitDateTime) => ({
        ...dateTime,
        startDate: toDate(dateTime.startDate!),
      }));
  }

  return validDateTimes.length ? validDateTimes : undefined;
};

export const useInitialVisitorsValue = (): PartialInitialValues => {
  const { search } = useLocation();
  const [groupUuid] = useQueryParam(CREATE_ROUTE_QUERY_PARAM);
  const visitorGroupDetails: Record<string, VisitorGroup | null> = useSelector(visitorGroupSelector);
  const visitorGroup: VisitorGroup | null = visitorGroupDetails?.[groupUuid];

  return useMemo(() => {
    if (!search) {
      return { visitors: [initialVisitorValues] };
    }

    let searchParams: qs.ParsedQs;

    try {
      searchParams = qs.parse(search, { ignoreQueryPrefix: true });
    } catch (error) {
      return { visitors: [initialVisitorValues] };
    }

    return Object.entries({
      ...searchParams,
      visitors: extractVisitors(searchParams, visitorGroup),
      visitDateTimes: extractVisitDateTimes(searchParams),
      visitTypeId: extractVisitTypeId(searchParams),
      groupName: visitorGroup?.name,
    })
      .filter(([_, value]) => !!value)
      .reduce((values, [key, value]) => ({ ...values, [key]: value }), {} as PartialInitialValues);
  }, [search]);
};
